import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/radix/DropdownMenu'
import { CogIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/solid'
import { NodeViewWrapper } from '@tiptap/react'
import { v4 as uuid } from 'uuid'
import TextareaAutosize from 'react-textarea-autosize'
import CodeArea from '@/components/CodeArea'

const MulticodeComponent: React.FC<any> = (props) => {
  const data = JSON.parse(props.node.attrs.data)
  const activeValue = data.find((item: any) => item.id === props.node.attrs.activeCode)

  const handleDescriptionChange = (event: any) => {
    props.updateAttributes({
      data: JSON.stringify(
        data.map((item: any) => {
          if (item.id === activeValue?.id) {
            return { ...item, value: event.target.value }
          }
          return item
        })
      ),
    })
  }

  const handleNameChange = (event: any, id: string) => {
    props.updateAttributes({
      data: JSON.stringify(
        data.map((item: any) => {
          if (item.id === id) {
            return { ...item, name: event.target.value }
          }
          return item
        })
      ),
    })
  }
  const handleRemoveItem = (id: string) => {
    const newData = data.filter((item: any) => item.id !== id)
    const newActiveCode = newData.length ? newData[0].id : null

    props.updateAttributes({
      data: JSON.stringify(newData),
      activeCode: newActiveCode,
    })
  }

  return (
    <NodeViewWrapper>
      <CodeArea
        activeItem={activeValue?.id}
        updateAttributes={props.updateAttributes}
        editorElement={
          <TextareaAutosize
            onChange={handleDescriptionChange}
            autoFocus
            rows={1}
            value={activeValue?.value}
            placeholder="Code here"
            className="p-0 text-sm whitespace-pre-wrap bg-transparent border-0 rounded-none shadow-none resize-none placeholder:text-gray-300/60 dark:bg-transparent ring-0 custom-scrollbar-stronger focus:ring-0"
          />
        }
        examples={data}
        menu={
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <button className="h-6 w-6 ml-1.5 p-0 flex items-center justify-center dashboard-secondary">
                <CogIcon className="!w-3 !mr-0 !h-3 secondary-svg" />
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-64 p-2 pt-0 divide-y dashboard-divide" align="end">
              {data.map((item: any) => (
                <div key={item.id} className="flex items-center gap-2">
                  <input
                    type="text"
                    value={item.name}
                    onChange={(e) => handleNameChange(e, item.id)}
                    className="flex-1 p-1 py-3 text-sm font-medium bg-transparent border-0 rounded dark:bg-transparent focus:ring-0"
                  />
                  <button onClick={() => handleRemoveItem(item.id)} className="">
                    <TrashIcon className="w-4 h-4 secondary-svg" />
                  </button>
                </div>
              ))}
              <div className="pt-1.5">
                <button
                  onClick={() => {
                    const newId = uuid()
                    props.updateAttributes &&
                      props.updateAttributes({
                        data: JSON.stringify([...data, { name: 'New', value: '', id: newId }]),
                        activeCode: newId,
                      })
                  }}
                  className="w-full mx-0 dropdown-item"
                >
                  <PlusCircleIcon className="w-4 h-4 secondary-svg mr-1.5" />
                  Add new
                </button>
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        }
      />
      {/* <pre contentEditable={false} className="relative p-0 overflow-hidden border rounded-md">
        <div className="flex items-center justify-between px-4 border-b">
          <div className="py-3 ">
            <span className="font-semibold text-gray-500 dark:text-foreground">Example</span>
          </div>
          <div className="flex items-center gap-2 -mb-px">
            {data.map((item: any) => (
              <button
                key={item?.id}
                className={cn(
                  'flex rounded-none focus:ring-0 items-center  py-4 px-1 justify-between cursor-pointer text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 main-transition dark:text-foreground',
                  item?.id === props.node.attrs.activeCode &&
                    'border-accent/60 text-accent dark:text-accent-foreground border-b'
                )}
                onClick={() => {
                  props.updateAttributes({
                    activeCode: item.id,
                  })
                }}
              >
                <div className="flex items-center space-x-2 font-semibold">
                  <span className="text-sm">{item.name}</span>
                </div>
              </button>
            ))}

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <button className="h-6 w-6 ml-1.5 p-0 flex items-center justify-center dashboard-secondary">
                  <CogIcon className="!w-3 !mr-0 !h-3 secondary-svg" />
                </button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-64 p-2 pt-0 divide-y dashboard-divide" align="end">
                {data.map((item: any) => (
                  <div key={item.id} className="flex items-center gap-2">
                    <input
                      type="text"
                      value={item.name}
                      onChange={(e) => handleNameChange(e, item.id)}
                      className="flex-1 p-1 py-3 text-sm font-medium bg-transparent border-0 rounded dark:bg-transparent focus:ring-0"
                    />
                    <button onClick={() => handleRemoveItem(item.id)} className="">
                      <TrashIcon className="w-4 h-4 secondary-svg" />
                    </button>
                  </div>
                ))}
                <div className="pt-1.5">
                  <button
                    onClick={() => {
                      const newId = uuid()
                      props.updateAttributes({
                        data: JSON.stringify([...data, { name: 'New', value: '', id: newId }]),
                        activeCode: newId,
                      })
                    }}
                    className="w-full mx-0 dropdown-item"
                  >
                    <PlusCircleIcon className="w-4 h-4 secondary-svg mr-1.5" />
                    Add new
                  </button>
                </div>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <div className="px-4 py-3 ">
          <code className="relative ">
            <TextareaAutosize
              onChange={handleDescriptionChange}
              autoFocus
              rows={1}
              value={activeValue?.value}
              placeholder="Code here"
              className="p-0 text-sm whitespace-pre-wrap bg-transparent border-0 rounded-none shadow-none resize-none placeholder:text-gray-300/60 dark:bg-transparent ring-0 custom-scrollbar-stronger focus:ring-0"
            />
          </code>
        </div>
      </pre> */}
    </NodeViewWrapper>
  )
}

export default MulticodeComponent
