import React, { useEffect, useState } from 'react'
import { linkLinearIssue, searchLinearIssues } from '../../network/lib/organization'
import { ISubmission } from '../interfaces/ISubmission'
import Loader from './Loader'
import { toast } from 'sonner'

interface ILinearSearchResult {
  boardOrder: number
  branchName: string
  completedAt: string
  createdAt: string
  customerTicketCount: number
  description: string
  id: string
  identifier: string
  number: number
  previousIdentifiers: string[]
  priority: string
  priorityLabel: string
  sortOrder: number
  startedAt: string
  title: string
  updatedAt: string
  url: string
}
const LinkExistingLinearIssue: React.FC<{
  submission: ISubmission
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  callback?: () => void
}> = ({ submission, setOpen, callback }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [noResults, setNoResults] = useState(false)
  const [loading, setLoading] = useState(false)
  const [linkLoading, setLinkLoading] = useState(false)
  const [activeResult, setActiveResult] = useState('')
  useEffect(() => {
    setLoading(true)
    const delayDebounceFn = setTimeout(() => {
      searchLinearIssues(searchTerm)
        .then((res) => {
          setSearchResults(res.data.nodes)
          setNoResults(res.data.nodes.length === 0)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })
      // Send Axios request here
    }, 200)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const linkIssue = (issueId: string) => {
    setActiveResult(issueId)
    setLinkLoading(true)
    linkLinearIssue(submission.id, issueId)
      .then((res) => {
        if (res.data.success) {
          setOpen(false)
          callback && callback()
          setLinkLoading(false)
          toast.success('Issue linked to Linear')
        }
      })
      .catch((err) => {
        toast.error('Error linking issue. ' + err.message)
        setOpen(false)
        setLinkLoading(false)
      })
  }

  return (
    <div className="mt-3">
      <input
        value={searchTerm}
        onChange={(event) => {
          setSearchTerm((prev) => event.target.value)
          setNoResults(false)
        }}
        autoFocus={true}
        placeholder="Issue title/content to search by"
        className="mb-2"
      />
      {loading && (
        <div className="flex items-center justify-center">
          <div className="w-5 h-5 my-3 secondary-svg">
            <Loader />
          </div>
        </div>
      )}
      {noResults && <p className="text-sm text-gray-400 dark:text-foreground">No results found</p>}
      <div className="divide-y divide-gray-100 dark:divide-dark-accent max-h-[400px] custom-scrollbar-stronger overflow-y-auto">
        {searchResults.map((result: ILinearSearchResult) => {
          return (
            <div key={result.id} className="py-3 text-white dark:shadow-sm">
              <div className="flex">
                <p className="p-1 px-1.5 text-xs up-element text-gray-400 bg-gray-50 dark:text-foreground tracking-wide font-medium rounded-md dark:shadow-none dark:border-dark-accent dark:bg-border">
                  {result.identifier}
                </p>
              </div>
              <p className="pt-2 pb-1 text-gray-900 dark:text-white">
                {result.title?.substring(0, 50)}
              </p>
              <p className="pb-1.5 text-background-accent text-sm truncate dark:text-foreground">
                {result.description}
              </p>
              <div className="flex justify-end">
                <button
                  className="items-center text-xs dashboard-primary"
                  onClick={() => {
                    linkIssue(result.id)
                  }}
                >
                  {linkLoading && activeResult === result.id && (
                    <div className="secondary-svg mr-1.5 h-3.5 w-3.5">
                      <Loader />
                    </div>
                  )}
                  Link to issue
                </button>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default LinkExistingLinearIssue
