import { AxiosError } from 'axios'
import { useAtom } from 'jotai'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { pushIssueToLinear } from '../../network/lib/organization'
import { popupNotificationsAtom } from '../atoms/notificationAtom'
import { useCurrentOrganization, useLinearTeamsAndUsers } from '../data/organization'
import { ISubmission } from '../interfaces/ISubmission'
import InlineError from './InlineError'
import { toast } from 'react-hot-toast'
import Loader from './Loader'
import { CommandGroup, CommandItem } from './radix/Command'
import MultiselectButton from './MultiselectButton'
import ModularComboBox from './radix/ModularComboBox'
import { getContentForNewPost } from './CreateNewClickupPost'
const CreateNewLinearPost: React.FC<{
  submission: ISubmission
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  callback?: () => void
}> = ({ submission, setOpen, callback }) => {
  const [issueTitle, setIssueTitle] = React.useState(submission.title)
  const [content, setContent] = useState('')
  const [selectedTeam, setSelectedTeam] = React.useState('')
  const [selectedUser, setSelectedUser] = React.useState('')
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const { linearTeamsAndUsers } = useLinearTeamsAndUsers()
  const { t } = useTranslation()
  const { org } = useCurrentOrganization()

  useEffect(() => {
    if (linearTeamsAndUsers) {
      setSelectedTeam(linearTeamsAndUsers.defaultTeamId)
      setSelectedUser('')
    }
  }, [linearTeamsAndUsers])

  useEffect(() => {
    getContentForNewPost(submission, org).then(setContent)
  }, [submission, org])

  const pushToLinear = () => {
    if (loading) return
    if (!selectedTeam) {
      setError('Please select a team')
      return
    }

    if (!issueTitle) {
      setError('Please enter a title')
      return
    }

    setLoading(true)

    pushIssueToLinear(
      issueTitle,
      content,
      selectedTeam,
      selectedUser,
      submission.id,
      submission.status
    )
      .then((res) => {
        if (res.data.success) {
          setOpen(false)
          setLoading(false)

          callback && callback()
          toast.success('Issue pushed to Linear')
        }
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        toast.error('Could not push issue to Linear. ' + err.response?.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <p className="mt-3 mb-1 text-sm font-medium text-gray-400 dark:text-foreground">Title</p>
      <input
        value={issueTitle}
        onChange={(event) => setIssueTitle((prev) => event.target.value)}
        placeholder={t('title-of-your-post')}
      />
      <div className="flex items-center mt-3">
        <p className="text-sm font-medium text-gray-400 dark:text-foreground">{t('content')}</p>
      </div>

      <div className="mt-1">
        <textarea
          rows={4}
          value={content}
          onChange={(event) => setContent((prev) => event.target.value)}
          //   placeholder={t('title-of-your-post')}
        />
      </div>
      {linearTeamsAndUsers ? (
        <div className="flex w-full gap-4">
          <div className="w-full">
            <div className="flex items-center mt-3 ">
              <p className="mb-1 text-sm font-medium text-gray-400 dark:text-foreground">Team</p>
            </div>
            <div className="">
              <ModularComboBox
                searchableDisplayName="Team"
                TriggerButton={() => (
                  <MultiselectButton className="h-8">
                    {linearTeamsAndUsers.teams.find((t) => t.id === selectedTeam)?.name || ''}
                  </MultiselectButton>
                )}
                CommandItems={({ closeComboBox }) => (
                  <CommandGroup>
                    {linearTeamsAndUsers.teams.map((t) => (
                      <CommandItem
                        key={t.id}
                        onSelect={() => {
                          setSelectedTeam(t.id)
                          closeComboBox()
                        }}
                      >
                        {t.name}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                )}
                popoverContentProps={{
                  align: 'start',
                }}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="flex items-center mt-3 ">
              <p className="mb-1 text-sm font-medium text-gray-400 dark:text-foreground">
                Assigned User
              </p>
            </div>
            <div>
              <ModularComboBox
                searchableDisplayName="Assigned User"
                TriggerButton={() => (
                  <MultiselectButton className="h-8">
                    {linearTeamsAndUsers.users.find((u) => u.id === selectedUser)?.name ||
                      'Unassigned'}
                  </MultiselectButton>
                )}
                CommandItems={({ closeComboBox }) => (
                  <CommandGroup>
                    {linearTeamsAndUsers.users.map((u) => (
                      <CommandItem
                        key={u.id}
                        onSelect={() => {
                          setSelectedUser(u.id)
                          closeComboBox()
                        }}
                      >
                        {u.name}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                )}
                popoverContentProps={{
                  align: 'start',
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center mt-8 mb-4">
          {' '}
          <div className="w-5 h-5 secondary-svg">
            <Loader />
          </div>
        </div>
      )}

      {error && <InlineError error={error}></InlineError>}
      <div className="flex justify-end w-full pt-4">
        <button onClick={() => pushToLinear()} className="dashboard-primary">
          {loading && (
            <div className="secondary-svg mr-1.5">
              {' '}
              <Loader />
            </div>
          )}
          Push to Linear
        </button>
      </div>
    </div>
  )
}

export default CreateNewLinearPost
