import { AxiosError } from 'axios'
import { useAtom } from 'jotai'
import { useTranslation } from 'next-i18next'
import React, { useEffect } from 'react'
import {
  pushIssueToGithub,
  updateOrganization,
} from '../../network/lib/organization'
import { popupNotificationsAtom } from '../atoms/notificationAtom'
import { useGithubConfig } from '../data/organization'
import { ISubmission } from '../interfaces/ISubmission'
import InlineError from './InlineError'
import { toast } from 'react-hot-toast'
import Loader from './Loader'

import MultiselectButton from './MultiselectButton'
import ModularComboBox from './radix/ModularComboBox'
import { CommandGroup, CommandItem } from './radix/Command'
import { getOrganizationUrl } from '@/lib/subdomain'

const createMarkdownForGithub = (submission: ISubmission) => {
  const submissionUrl = getOrganizationUrl(submission.organization) + '/p/' + submission.slug

  return `This issue is linked to our feedback platform. For more details and updates, please visit [this link](${submissionUrl}).`
}

const CreateNewGithubIssue: React.FC<{
  submission: ISubmission
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  callback?: () => void
}> = ({ submission, setOpen, callback }) => {
  const [issueTitle, setIssueTitle] = React.useState(submission.title)
  // dompurify to remove html tags from content
  const [content, setContent] = React.useState(createMarkdownForGithub(submission))
  const [popupNotifications, setPopupNotifications] = useAtom(popupNotificationsAtom)

  //   const [selectedUser, setSelectedUser] = React.useState('')
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const { githubConfig } = useGithubConfig()
  const { t } = useTranslation()
  const [selectedRepoId, setSelectedRepoId] = React.useState('')

  useEffect(() => {
    // set default team
    if (!githubConfig) return
    if (githubConfig.previouslySelectedRepoId) {
      setSelectedRepoId(githubConfig.previouslySelectedRepoId)
    }
  }, [githubConfig])

  const pushToGithub = () => {
    if (loading) return
    if (!selectedRepoId) {
      setError('Please select a repo')
      return
    }

    if (!issueTitle) {
      setError('Please enter a title')
      return
    }

    setLoading(true)

    pushIssueToGithub({
      title: issueTitle,
      description: content,
      submissionId: submission.id,
      repoId: selectedRepoId,
    })
      .then((res) => {
        if (res.data.success) {
          setOpen(false)
          setLoading(false)

          callback && callback()
          toast.success('Issue pushed to GitHub')
          // save the selected team, folder, list for next time
          updateOrganization({
            githubPreviouslySelectedRepoId: selectedRepoId,
          }).catch(() => {
            toast.error('Error saving GitHub data')
          })
        }
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        toast.error('Could not push issue to GitHub. ' + err.response?.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <div>
      <p className="mt-3 mb-1 text-sm font-medium text-gray-400 dark:text-foreground">
        Issue title
      </p>
      <input
        value={issueTitle}
        onChange={(event) => setIssueTitle((prev) => event.target.value)}
        placeholder={t('title-of-your-post')}
      />
      <div className="flex items-center mt-3">
        <p className="text-sm font-medium text-gray-400 dark:text-foreground">{t('content')}</p>
      </div>

      <div className="mt-1">
        <textarea
          rows={4}
          value={content}
          onChange={(event) => setContent((prev) => event.target.value)}
          //   placeholder={t('title-of-your-post')}
        />
      </div>
      {githubConfig ? (
        <div>
          <div className="flex w-full gap-4">
            <div className="w-full">
              <div className="flex items-center mt-3 ">
                <p className="mb-1 text-sm font-medium text-gray-400 dark:text-foreground">
                  Repository
                </p>
              </div>
              <div className="">
                <ModularComboBox
                  searchableDisplayName="Repositories"
                  TriggerButton={() => (
                    <MultiselectButton className="h-8">
                      {githubConfig.repositories.find((r) => r.id === selectedRepoId)?.name ||
                        'Select a Repo'}
                    </MultiselectButton>
                  )}
                  CommandItems={({ closeComboBox }) => (
                    <CommandGroup>
                      {githubConfig.repositories.map((r) => (
                        <CommandItem
                          key={r.id}
                          onSelect={() => {
                            setSelectedRepoId(r.id)
                            closeComboBox()
                          }}
                        >
                          {r.name}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  )}
                  popoverContentProps={{
                    align: 'start',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center mt-8 mb-4">
          {' '}
          <div className="w-5 h-5 secondary-svg">
            <Loader />
          </div>
        </div>
      )}

      {error && <InlineError error={error}></InlineError>}
      <div className="flex justify-end w-full pt-4">
        <button onClick={() => pushToGithub()} className="dashboard-primary">
          {loading && (
            <div className="secondary-svg mr-1.5">
              {' '}
              <Loader />
            </div>
          )}
          Push to GitHub
        </button>
      </div>
    </div>
  )
}

export default CreateNewGithubIssue
