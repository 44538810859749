import * as React from 'react'
import * as HeroIcons from '@heroicons/react/solid'
import { IconList } from './iconType'

interface IconPickerItemProps {
  icon: IconList
  size?: number
}

const IconPickerItem: React.FC<IconPickerItemProps> = ({ icon, size = 6 }) => {
  const IconComponent = HeroIcons[icon as keyof typeof HeroIcons]

  if (!IconComponent) {
    console.warn(`Icon "${icon}" not found`)
    return null
  }

  return (
    <div className={`!w-${size} !h-${size} !m-0 !p-0`}>
      <IconComponent />
    </div>
  )
}

export { IconPickerItem }
