import React from 'react'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './radix/Tooltip'
import { EyeOffIcon } from '@heroicons/react/solid'
import { useUser } from '@/data/user'
import { useCurrentOrganization } from '@/data/organization'
import { isMember } from '@/lib/acl'

const PrivateWithAuthorBadge: React.FC<{ submissionAuthorId?: string; authorEmail?: string }> = ({
  submissionAuthorId,
  authorEmail,
}) => {
  const { user } = useUser()
  const { org } = useCurrentOrganization()

  const isAuthorAdmin =
    (submissionAuthorId && isMember(submissionAuthorId, org)) || !authorEmail ? true : false

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            className={
              'text-xs px-1.5 inline-flex space-x-1 items-center font-medium text-gray-400 dark:text-foreground'
            }
          >
            <EyeOffIcon className="h-3.5 w-3.5 mr-1 -ml-0.5 text-background-accent/50 dark:text-background-accent" />
            {isMember(user?.id, org)
              ? isAuthorAdmin
                ? 'Admins only'
                : 'Author only'
              : 'Only shown to you'}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p className="text-[11px] text-background-accent dark:text-foreground font-medium">
            {isMember(user?.id, org)
              ? `This post is only visible to the ${isAuthorAdmin ? 'admins' : 'author'}.`
              : 'This post is only visible to you and the admins.'}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default PrivateWithAuthorBadge
