var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b11f21fc333e3af2f36c5dd133a4db0249705ff4"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// // This file configures the initialization of Sentry on the browser.
// // The config you add here will be used whenever a page is visited.
// // https://docs.sentry.io/platforms/javascript/guides/nextjs/

// import * as Sentry from '@sentry/nextjs'

// const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

// const DENY_URLS = [/widget/i, /^chrome-extension:\/\//i]

// Sentry.init({
//   dsn:
//     SENTRY_DSN ||
//     'https://acd1a34d390f4d35bf395c8f8d0fa1bd@o4504213851144192.ingest.sentry.io/4504213853175808',
//   // Adjust this value in production, or use tracesSampler for greater control
//   tracesSampleRate: 0,
//   // ...
//   // Note: if you want to override the automatic release value, do not set a
//   // `release` value here - use the environment variable `SENTRY_RELEASE`, so
//   // that it will also get attached to your source maps
//   // This sets the sample rate to be 10%. You may want this to be 100% while
//   // in development and sample at a lower rate in production
//   replaysSessionSampleRate: 0,
//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,
//   ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured'],
//   enableTracing: false,
//   // enabled: process.env.NODE_ENV !== 'development',
//   // denyUrls: [/widget/i],
//   ignoreUrls: [
//     // Facebook flakiness
//     /graph\.facebook\.com/i,
//     // Facebook blocked
//     /connect\.facebook\.net\/en_US\/all\.js/i,
//     // Woopra flakiness
//     /eatdifferent\.com\.woopra-ns\.com/i,
//     /static\.woopra\.com\/js\/woopra\.js/i,
//     // Chrome extensions
//     /extensions\//i,
//     /^chrome:\/\//i,
//     // Other plugins
//     /widget\//i,
//   ],
// })
