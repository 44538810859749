import { useCallback, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { ColorButton } from './ColorButton'
import { Toolbar } from '../../ui/Toolbar'
import { Icon } from '../../ui/Icon'
import { themeColors } from '@/lib/editorConstants'

export type ColorPickerProps = {
  color?: string
  onChange?: (color: string) => void
  onClear?: () => void
}

export const ColorPicker = ({ color, onChange, onClear }: ColorPickerProps) => {
  const [colorInputValue, setColorInputValue] = useState(color || '')

  const handleColorUpdate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setColorInputValue(event.target.value)
  }, [])

  const handleColorChange = useCallback(() => {
    const isCorrectColor = /^#([0-9A-F]{3}){1,2}$/i.test(colorInputValue)

    if (!isCorrectColor) {
      if (onChange) {
        onChange('')
      }

      return
    }

    if (onChange) {
      onChange(colorInputValue)
    }
  }, [colorInputValue, onChange])

  return (
    <div className="flex flex-col gap-2 -mx-1 -mt-1">
      <HexColorPicker className="!w-full !rounded-none" color={color || ''} onChange={onChange} />
      <div className=" px-2">
        <input
          type="text"
          className="w-full p-2 border"
          placeholder="#000000"
          value={colorInputValue}
          onChange={handleColorUpdate}
          onBlur={handleColorChange}
        />
        <div className="flex flex-wrap items-center gap-1 mt-1.5 max-w-[15rem]">
          {themeColors.map((currentColor) => (
            <ColorButton
              active={currentColor === color}
              color={currentColor}
              key={currentColor}
              onColorChange={onChange}
            />
          ))}
          <Toolbar.Button
            className="!rounded-md !h-7"
            tooltip="Reset color to default"
            onClick={onClear}
          >
            <Icon name="Undo" />
          </Toolbar.Button>
        </div>
      </div>
    </div>
  )
}
