import { Spinner } from '@/components/editor/components/ui/Spinner'
import { useDropZone, useFileUpload, useUploader } from './hooks'
import { cn } from '@/lib/utils'
import { ChangeEvent, useCallback } from 'react'
import { PhotographIcon } from '@heroicons/react/solid'

export const ImageUploader = ({ onUpload }: { onUpload: (url: string) => void }) => {
  const { loading, uploadFile } = useUploader({ onUpload })
  const { handleUploadClick, ref } = useFileUpload()
  const { draggedInside, onDrop, onDragEnter, onDragLeave } = useDropZone({
    uploader: (file) => uploadFile([file]),
  })

  const onFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => (e.target.files ? uploadFile([e.target.files[0]]) : null),
    [uploadFile]
  )

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8 rounded-lg min-h-[10rem] bg-opacity-80">
        <Spinner className="secondary-svg" size={1.5} />
      </div>
    )
  }

  const wrapperClass = cn(
    'flex items-center justify-center cursor-pointer px-6 py-6 rounded-lg bg-opacity-80',
    draggedInside && 'bg-gray-50 dark:bg-secondary'
  )

  return (
    <div
      className={wrapperClass}
      onDrop={onDrop}
      onDragOver={onDragEnter}
      onDragLeave={onDragLeave}
      contentEditable={false}
      onClick={() => handleUploadClick()}
    >
      {/* <Icon name="Image" className="!w-12 !h-12" /> */}
      <PhotographIcon className="!w-10 !h-10 secondary-svg mr-1.5" />
      <div className="flex flex-col items-center justify-center gap-2">
        <div className="text-sm font-medium text-center">
          {draggedInside ? 'Drop image here' : 'Add an image'}
        </div>
      </div>
      <input
        className="w-0 h-0 overflow-hidden opacity-0"
        ref={ref}
        type="file"
        accept=".jpg,.jpeg,.png,.webp,.gif"
        onChange={onFileChange}
      />
    </div>
  )
}

export default ImageUploader
