import Link from '@/components/CustomLink'
import { Disclosure, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/solid'
import { helpCenterUrlPartsAtom } from '@/atoms/orgAtom'
import { useAtom } from 'jotai'
import Loader from './Loader'
import { useKnowledgebaseStructure } from '@/data/knowledgebase'
import { memo, useEffect, useMemo, useState } from 'react'
import { IHelpCenterArticleDocument } from '@/interfaces/IHelpCenter'
import Image from 'next/image'
import { useCurrentOrganization } from '@/data/organization'
import SearchArticles from './docs/SearchArticles'
import NavItemNormal from './docs/NavItemNormal'
import NavItemCollapsed from './docs/NavItemCollapsed'
import MenuContent from './docs/MenuContent'

const MemoCollapsedItem = memo(NavItemCollapsed)

export type navData =
  | {
      name: string
      current: boolean
      href: string
      icon: IHelpCenterArticleDocument['icon']
      slug: string
      collectionId: string
      pages: {
        name: string
        href: string
        icon: IHelpCenterArticleDocument['icon']
        slug: string
        collectionId: string
      }[]
    }[]
  | []

export type navItemType = navData[0]

const processNavData = (
  data: any,
  urlParts: {
    subpath: string
    locale: string
  }
) => {
  return (
    data?.structure?.map((item: any) => ({
      name: item.type === 'collection' ? item.name : item.title,
      href: `${urlParts.subpath}${item.type === 'collection' ? 'collections' : 'articles'}/${
        item.slug
      }`,
      icon: item?.icon,
      slug: item.slug,
      collectionId: item.collectionId,
      pages: item.structure.map((subItem: any) => ({
        name: subItem.type === 'collection' ? subItem.name : subItem.title,
        href: `${urlParts.subpath}${subItem.type === 'collection' ? 'collections' : 'articles'}/${
          subItem.slug
        }`,
        icon: subItem?.icon,
        slug: subItem.slug,
        collectionId: subItem.collectionId,
      })),
    })) || []
  )
}

const Navigation = () => {
  const { data } = useKnowledgebaseStructure()

  const [helpCenterUrlParts] = useAtom(helpCenterUrlPartsAtom)

  const { org } = useCurrentOrganization()

  const [navData, setNavData] = useState<navData>(processNavData(data, helpCenterUrlParts))

  const memoizedNavItems = useMemo(
    () => (close: () => void) =>
      navData.map((navItem, index) =>
        navData?.length > 4 ? (
          <NavItemCollapsed key={navItem.slug} navItem={navItem} index={index} close={close} />
        ) : (
          <NavItemNormal key={navItem.slug} navItem={navItem} index={index} close={close} />
        )
      ),
    [navData]
  )

  const MemoizedMenuContent = useMemo(() => {
    const MenuContentWrapper = ({ closeFunc }: { closeFunc: () => void }) => (
      <MenuContent close={closeFunc} memoizedNavItems={memoizedNavItems(closeFunc)} data={data} />
    )
    MenuContentWrapper.displayName = 'MemoizedMenuContent'
    return MenuContentWrapper
  }, [memoizedNavItems, data])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <>
      <Disclosure as="nav">
        {({ open, close }) => (
          <>
            <div className="relative top-0 left-0 z-10 h-full border-r shadow-sm xl:min-h-screen xl:fixed dark:border-border/60 shadow-gray-200/20 dark:shadow-md dashboard-border">
              <div className="z-50 h-full custom-scrollbar-stronger overscroll-contain rounded-none w-[278px] flex-col justify-between hidden overflow-x-hidden xl:flex dark:border-border ">
                <MemoizedMenuContent closeFunc={close} />
              </div>
            </div>
            <div className="flex items-center justify-between w-full px-4 py-4 border-b dark:bg-secondary/40 dashboard-border xl:hidden">
              <Link className="w-full truncate" href={`${helpCenterUrlParts.subpath}`}>
                <button className="inline-flex items-center max-w-full truncate unstyled-button">
                  <div className="flex items-center gap-2.5">
                    <div className="flex items-center justify-center flex-shrink-0 mr-0 overflow-hidden rounded-full w-7 h-7 sm:h-7 sm:w-7 dark:bg-secondary dark:border-secondary">
                      {org?.picture ? (
                        <Image
                          className="object-cover rounded-full"
                          src={org?.picture}
                          height={28}
                          width={28}
                          alt="profile_pic"
                        />
                      ) : (
                        <div className="flex items-center justify-center text-gray-400 dark:text-background-accent">
                          <div className="w-5 h-5 ">
                            <Loader />
                          </div>
                        </div>
                      )}
                    </div>
                    <h2 className="w-full mt-px text-sm font-semibold leading-none text-gray-600 truncate transform-gpu sm:text-base dark:text-gray-50">
                      {org?.displayName}
                    </h2>
                  </div>
                </button>
              </Link>
              <Disclosure.Button className="h-8 px-2 dashboard-secondary">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XIcon className="block w-6 h-6" aria-hidden="true" />
                ) : (
                  <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                )}
              </Disclosure.Button>
            </div>
            <div className="relative z-[100]">
              <Transition
                appear={true}
                show={open}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100 "
                leave="ease-in duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Disclosure.Panel className="fixed inset-0 flex flex-col justify-between pb-3 bg-gray-50/80 xl:hidden dark:bg-background/[85%] backdrop-blur-md">
                  <MemoizedMenuContent closeFunc={close} />
                </Disclosure.Panel>
              </Transition>
            </div>
          </>
        )}
      </Disclosure>
    </>
  )
}

Navigation.theme = 'light'
export default Navigation
