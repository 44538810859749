import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'

import Loader from './Loader'

import { useCurrentOrganization } from '../data/organization'
import { ISubmissionFilters } from '../interfaces/ISubmission'
import { useUser } from '../data/user'
import MainRoadmapButtons from './MainRoadmapButtons'
import { IOrganizationStatus } from '../interfaces/IOrganization'
import { sortOrder } from '../pages/dashboard/settings/statuses'
import DedicatedRoadmapField from './DedicatedRoadmapField'
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@alissavrk/dnd-kit-core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import PopupWrapper from './PopupWrapper'
import MainPostView from './MainPostView'
import { useSingleSubmission } from '@/data/submission'
import { cn, generateRoadmapMainFilters, getRoadmapDefaultFilters } from '@/lib/utils'
import useElementSize from './useElementSize'
import PopularitySorter from './PopularitySorter'
import { useRouter } from 'next/router'
import MainFilterDropdown, { QueryEntry } from './MainFilterDropdown'
import ActiveFilterTab from './ActiveFilterTab'
import { useAtom } from 'jotai'
import {
  roadmapCurrentSubmissionParentFilterAtom,
  submissionActiveFilterURIAtom,
} from '@/atoms/submissionAtom'
import FilterSyncer from './FilterSyncer'
import useSubmissionUrl from '@/hooks/submissionUrlSyncer'

export const monthNames = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
]

function getQuarterLabel(date: Date) {
  const monthIndex = date.getUTCMonth() // 0-11
  const year = date.getUTCFullYear().toString().slice(-2) // Last two digits of year

  const quarter = Math.floor(monthIndex / 3) + 1 // Calculate quarter
  return `Q${quarter}'${year}`
}

function getMonthLabel(date: Date) {
  const monthIndex = date.getUTCMonth() // 0-11
  const year = date.getFullYear().toString().slice(-2) // Last two digits of year

  return `${monthNames[monthIndex]}'${year}`
}

const MainRoadmapView: React.FC<{
  isDashboard?: boolean
  enableRedirection?: boolean
  widget?: boolean
  initialFiltersForFrontend?: ISubmissionFilters
  mode: 'status' | 'quarterly' | 'monthly' | null
}> = ({ isDashboard = false, enableRedirection, widget, initialFiltersForFrontend, mode }) => {
  const { user } = useUser()
  const { org } = useCurrentOrganization()
  const [filterMode, setFilterMode] = useState<'status' | 'quarterly' | 'monthly'>(mode || 'status')

  const categoryIds = org?.structure?.roadmap?.hiddenCategories?.map(
    (cat) => org?.postCategories?.find((c) => c.category === cat)?.id || ''
  )

  const [filters, setFilters] = useState<ISubmissionFilters>(
    initialFiltersForFrontend ? initialFiltersForFrontend : getRoadmapDefaultFilters(org)
  )

  const [activeFilterURI, setActiveFilterURI] = useAtom(submissionActiveFilterURIAtom)

  const [activeId, setActiveId] = useState(null)
  const [remutateSubmissionsWithStatus, setRemutateSubmissionsWithStatus] = useState<string[]>([])
  const [ActiveElement, setActiveElement] = useState(null)
  const [mainPostView, setMainPostView] = useState(false)
  const [activeSubmissionId, setActiveSubmissionId] = useState('')

  const router = useRouter()
  const getFilteredAndSortedStatuses = (statuses: IOrganizationStatus[]) => {
    return statuses.sort((a, b) => sortOrder[a.type] - sortOrder[b.type])
  }

  const generatedStatuses = () => {
    return getFilteredAndSortedStatuses(org.postStatuses)
      ?.filter((status) => org?.roadmapStatuses?.includes(status.name))
      ?.map((status) => status.name)
  }

  const [activeLabels, setActiveLabels] = useState<string[]>(generatedStatuses())
  const [activeQuarters, setActiveQuarters] = useState<string[] | undefined>(undefined)
  const [activeMonths, setActiveMonths] = useState<string[] | undefined>(undefined)
  const [parentFilters, setParentFilters] = useAtom(roadmapCurrentSubmissionParentFilterAtom)
  const { restoreUrl, setUrl } = useSubmissionUrl(true)

  const setFilterToMonthly = () => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    const newActiveMonths = !activeMonths
      ? Array.from({ length: 4 }, (_, i) => {
          const monthNum = ((currentMonth + i - 1) % 12) + 1
          const year = currentYear + Math.floor((currentMonth + i - 1) / 12)
          return `${monthNames[monthNum - 1]}'${year.toString().slice(-2)}`
        })
      : activeMonths

    setActiveLabels(newActiveMonths)
    setActiveMonths(newActiveMonths)
  }

  const setFilterToQuarterly = () => {
    const currentYear = new Date().getFullYear()
    const currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3)
    const newActiveQuarters = !activeQuarters
      ? Array.from({ length: 4 }, (_, i) => {
          const quarterNum = ((currentQuarter + i - 1) % 4) + 1
          const year = currentYear + Math.floor((currentQuarter + i - 1) / 4)
          return `Q${quarterNum}'${year.toString().slice(-2)}`
        })
      : activeQuarters

    setActiveLabels(newActiveQuarters)
    setActiveQuarters(newActiveQuarters)
  }

  const {
    submission,
    mutateSingleSubmission,
    rawSubmissionData: rawSingleSubmissionData,
  } = useSingleSubmission(activeSubmissionId)

  function handleDragStart(event: any) {
    setActiveId(event.active.id)
  }

  function handleDragEnd(event: any) {
    setActiveId(null)
  }
  const [elementRef, { width }] = useElementSize()

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  )

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  // If mounts and mode is set, set the filter mode to the mode
  useEffect(() => {
    if (router.isReady) {
      const { mode: queryMode } = router.query
      if (queryMode && ['status', 'quarterly', 'monthly'].includes(queryMode as string)) {
        setFilterMode(queryMode as 'status' | 'quarterly' | 'monthly')

        // Generate the labels based on the mode
        if (queryMode === 'quarterly') {
          setFilterToQuarterly()
        } else if (queryMode === 'monthly') {
          setFilterToMonthly()
        } else {
          setActiveLabels(generatedStatuses())
        }
      }
    }
  }, [router.isReady, router.query])

  const mutatorInterceptor = (data: any): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        if (data?.[0] === 'Deleted') {
          let labels = [submission.postStatus.name]

          if (submission.eta) {
            labels.push(labelForDate(new Date(submission.eta)))
          }

          setRemutateSubmissionsWithStatus(labels)
          resolve()
          return
        }

        mutateSubmissionIfNeeded(data)
        updateSubmissionStatusAndLabelsIfNeeded(data)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  const mutateSubmissionIfNeeded = (data: any): void => {
    if (data) {
      mutateSingleSubmission(data, false)
    } else {
      mutateSingleSubmission()
    }
  }

  const updateSubmissionStatusAndLabelsIfNeeded = (data: any): void => {
    const oldSubmission = submission
    const newSubmission = data?.results[0]

    if (!newSubmission) return

    let labels = []
    if (filterMode === 'status') {
      setRemutateSubmissionsWithStatus([
        newSubmission.postStatus.name,
        oldSubmission.postStatus.name,
      ])
    } else {
      labels.push(...extractLabels(oldSubmission), ...extractLabels(newSubmission))
      setRemutateSubmissionsWithStatus(labels)
    }
  }

  const extractLabels = (submission: any): string[] => {
    return submission?.eta ? [labelForDate(new Date(submission.eta))] : []
  }

  const labelForDate = (date: Date): string => {
    return filterMode === 'monthly' ? getMonthLabel(date) : getQuarterLabel(date)
  }

  function generateQuarters(forward: boolean) {
    if (activeQuarters) {
      const quarters: string[] = []

      activeQuarters.forEach((quarter) => {
        const qNum = parseInt(quarter[1])
        const year =
          parseInt(quarter.slice(3, 5)) +
          (qNum === 4 && forward ? 1 : qNum === 1 && !forward ? -1 : 0)
        const newQNum = forward ? (qNum % 4) + 1 : qNum === 1 ? 4 : qNum - 1
        quarters.push(`Q${newQNum}'${year.toString().padStart(2, '0')}`)
      })

      setActiveQuarters(quarters)
      setActiveLabels(quarters)
    }
  }

  function generateMonths(forward: boolean) {
    if (activeMonths) {
      const newActiveMonths: string[] = []

      activeMonths.forEach((month) => {
        const monthIndex = monthNames.indexOf(month.toLowerCase().slice(0, 3))
        const currentYear = parseInt(month.slice(-2), 10)

        let newMonthIndex, newYear

        if (forward) {
          newMonthIndex = (monthIndex + 1) % 12
          newYear = monthIndex === 11 ? currentYear + 1 : currentYear
        } else {
          newMonthIndex = monthIndex === 0 ? 11 : monthIndex - 1
          newYear = monthIndex === 0 ? currentYear - 1 : currentYear
        }

        newActiveMonths.push(`${monthNames[newMonthIndex]}'${newYear.toString().padStart(2, '0')}`)
      })

      setActiveMonths(newActiveMonths)
      setActiveLabels(newActiveMonths)
    }
  }

  useEffect(() => {
    if (!submission) return
    const mainFilters = submission
      ? generateRoadmapMainFilters(
          filterMode === 'status',
          filterMode !== 'status'
            ? submission?.eta
              ? filterMode === 'monthly'
                ? getMonthLabel(new Date(submission?.eta))
                : getQuarterLabel(new Date(submission?.eta))
              : ''
            : submission?.postStatus?.id
        )
      : []

    const roadmapFilters = {
      ...filters,
      advancedFilters: [...filters.advancedFilters, ...mainFilters],
    }
    setParentFilters(roadmapFilters)
  }, [JSON.stringify(filters), submission?.id, submission?.eta, filterMode])

  return (
    <div ref={elementRef} style={{ minHeight: !isDashboard ? 'calc(100vh - 141px)' : '' }}>
      <div className="mt-1">
        <PopupWrapper
          fullScreen={true}
          isOpen={mainPostView}
          setIsOpen={() => {
            setMainPostView(false)
            restoreUrl()
          }}
          large={true}
          hasPadding={false}
        >
          {activeSubmissionId !== '' && submission ? (
            <MainPostView
              roadmapView={true}
              setActiveSubmissionId={setActiveSubmissionId}
              setOpen={setMainPostView}
              rawSubmissionData={rawSingleSubmissionData}
              mutateSubmissions={mutatorInterceptor as any}
              submission={submission}
              fetchResults={mainPostView}
            />
          ) : (
            <div className="flex items-center justify-center min-h-[426px]">
              <div className="text-background-accent h-7 w-7 dark:text-background-accent">
                <Loader />
              </div>
            </div>
          )}
        </PopupWrapper>

        <FilterSyncer
          setFilters={setFilters}
          filters={filters}
          setActiveFilterURI={setActiveFilterURI}
          defaultFilters={
            initialFiltersForFrontend ? initialFiltersForFrontend : getRoadmapDefaultFilters(org)
          }
          hideCompletedAndCancelled={false}
          roadmap={true}
        />

        {!widget ? (
          <div
            className={cn(
              'flex mx-auto flex-wrap gap-4 mt-6  items-center justify-between',
              isDashboard ? '' : 'max-w-5xl'
            )}
          >
            <div className="flex flex-wrap items-center">
              <MainRoadmapButtons
                generateQuarters={setFilterToQuarterly}
                generateMonths={setFilterToMonthly}
                filterMode={filterMode}
                org={org}
                setFilterMode={setFilterMode}
                generateStatuses={() => {
                  setActiveLabels(generatedStatuses())
                }}
              />
            </div>
            <div className="flex items-center space-x-2">
              <div className="relative z-30 inline-flex items-center gap-3 ml-auto">
                <MainFilterDropdown
                  big={true}
                  hideStatusOption={filterMode === 'status' ? true : false}
                  setActiveFilters={setFilters}
                  activeFilters={filters.advancedFilters}
                />
                <PopularitySorter filters={filters} setFilters={setFilters} />
              </div>
              {filterMode !== 'status' && (
                <>
                  <button
                    onClick={() =>
                      filterMode === 'quarterly' ? generateQuarters(false) : generateMonths(false)
                    }
                    className="p-2 dashboard-secondary"
                  >
                    <ChevronLeftIcon className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() =>
                      filterMode === 'quarterly' ? generateQuarters(true) : generateMonths(true)
                    }
                    className="p-2 dashboard-secondary"
                  >
                    <ChevronRightIcon className="w-5 h-5" />
                  </button>
                </>
              )}
            </div>
          </div>
        ) : null}
        <div
          className={cn(
            'flex items-center mx-auto justify-end mt-1',
            isDashboard ? '' : 'max-w-5xl'
          )}
        >
          <ActiveFilterTab
            roadmap={true}
            activeFilters={filters.advancedFilters}
            setActiveFilters={setFilters}
            categoryIds={categoryIds}
          />
        </div>
        <div
          className={cn(
            'relative mb-8 mt-3 mx-auto  w-full',
            isDashboard ? '' : activeLabels?.length > 3 ? 'max-w-8xl xl:px-4' : 'max-w-5xl'
          )}
        >
          {org && isMounted ? (
            <div
              className={`md:flex items-center justify-center md:w-full  custom-scrollbar overflow-x-auto  pl-2 -ml-2 `}
            >
              <div className="flex-row md:inline-flex md:w-full">
                <DndContext
                  sensors={sensors}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToWindowEdges]}
                >
                  {activeLabels.map((status, index) => {
                    const actualStatus = org?.postStatuses?.find((stat) => stat.name === status)

                    if (!actualStatus && filterMode === 'status') return null
                    else
                      return (
                        <DedicatedRoadmapField
                          enableRedirection={enableRedirection}
                          filterMode={filterMode}
                          activeId={activeId}
                          width={width}
                          key={`${status}-${index}`}
                          org={org}
                          // @ts-ignore
                          status={
                            filterMode === 'status'
                              ? actualStatus
                              : { id: status, name: 'non-all-222' }
                          }
                          user={user}
                          index={index}
                          setActiveElement={setActiveElement}
                          remutateSubmissionsWithStatus={remutateSubmissionsWithStatus}
                          setRemutateSubmissionsWithStatus={setRemutateSubmissionsWithStatus}
                          setActiveSubmissionId={setActiveSubmissionId}
                          setMainPostView={setMainPostView}
                          defaultFilters={filters}
                          isLast={index === activeLabels.length - 1}
                          moreThanTwo={activeLabels.length > 2}
                          setUrl={setUrl}
                          remutateSingleSubmission={() => mutateSingleSubmission()}
                        />
                      )
                  })}
                  <DragOverlay dropAnimation={null}>
                    {activeId && ActiveElement ? ActiveElement : null}
                  </DragOverlay>
                </DndContext>
              </div>
            </div>
          ) : (
            <div className="w-5 h-5 mx-auto mt-8 ">
              <div className="secondary-svg">
                <Loader />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MainRoadmapView
