const HttpBackend = require('i18next-http-backend/cjs')
const ChainedBackend = require('i18next-chained-backend').default
const LocalStorageBackend = require('i18next-localstorage-backend').default
const path = require('path')

const canReadLocalStorage = () => {
  try {
    localStorage?.setItem('test', 'test')
    localStorage?.removeItem('test')
    return true
  } catch (e) {
    return false
  }
}

const determineBackends = () => {
  if (typeof window === 'undefined') {
    return []
  }

  if (canReadLocalStorage()) {
    return [LocalStorageBackend, HttpBackend]
  }

  return [HttpBackend]
}

module.exports = {
  backend: {
    backendOptions: [
      { expirationTime: 60 * 60 * 1000 },
      {
        /* loadPath: 'https:// somewhere else' */
      },
    ], // 1 hour
    backends: determineBackends(),
  },
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'et', 'de', 'pt', 'pl', 'fr', 'es', 'ru', 'nl', 'kr'],
  },
  reloadOnPrerender: true,
  serializeConfig: false,
  use: typeof window !== 'undefined' ? [ChainedBackend] : [],
  localePath: path.resolve('./public/locales'),
}
