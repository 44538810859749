import React, { useEffect } from 'react'
import { CheckIcon, XIcon } from '@heroicons/react/solid'
import ObjectID from 'bson-objectid'
import ModularComboBox from './radix/ModularComboBox'
import { CommandGroup, CommandItem } from './radix/Command'
import { DatePicker } from './radix/DatePicker'
import { useCurrentOrganization } from '@/data/organization'
import MultiselectButton from './MultiselectButton'
import { cn } from '@/lib/utils'

const initialPropertyValues = [
  'Company Created',
  'Company ID',
  'Monthly Recurring Revenue',
  'Company Name',
  'User Created',
  'User ID',
  'User Email',
  'User Name',
  'User role',
]

const stringComparator = ['is', 'is not', 'is not null', 'is null', 'contains', 'does not contain']
const dateComparator = ['after date', 'before date', 'is not null', 'is null', 'on date']

const numberComparator = ['greater than', 'is', 'is not', 'is not null', 'is null', 'less than']
const stringArrayComparator = ['is one of', 'is not in']

interface SegmentItemProps {
  rule?: {
    _id: string
    name?: string
    field?: string
    fieldType?: string
    value?: string | string[]
    rule?: string
  }
  index: number
  onChange: (
    index: number,
    newRule: {
      _id: string
      name?: string
      field?: string
      fieldType?: string
      value?: string | string[]
      rule?: string
    }
  ) => void
  onRemove: (index: string) => void
}
interface PropertyTypes {
  [key: string]: string
}

export const propertyTypes: PropertyTypes = {
  'Company Created': 'date',
  'Company ID': 'string',
  'Monthly Recurring Revenue': 'number',
  'Company Name': 'string',
  'User Created': 'date',
  'User ID': 'string',
  'User Email': 'string',
  'User Name': 'string',
  'User role': 'string[]',
}

const fieldPaths = {
  'Company Created': 'companies.createdAt',
  'Company ID': 'companies.id',
  'Monthly Recurring Revenue': 'companies.monthlySpend',
  'Company Name': 'companies.name',
  'User Created': 'createdAt',
  'User ID': 'externalUserId',
  'User Email': 'email',
  'User Name': 'name',
  'User role': 'roles',
}

const SegmentItem: React.FC<SegmentItemProps> = ({ rule = {}, index, onChange, onRemove }) => {
  const [propertyValue, setPropertyValue] = React.useState<string>(rule.name || 'Select property')
  const [fieldValue, setFieldValue] = React.useState<string>(rule.field || '')
  const [valueType, setValueType] = React.useState(rule.fieldType || 'string')
  const [comparatorValue, setComparatorValue] = React.useState(rule.rule || 'Select filter')
  const [value, setValue] = React.useState<string | string[]>(rule.value || '')
  const id = rule['_id'] || new ObjectID().toString()

  const { org } = useCurrentOrganization()

  const handleTextValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    onChange(index, {
      _id: id,
      name: propertyValue,
      field: fieldValue,
      fieldType: valueType,
      value: value,
      rule: comparatorValue,
    })
  }, [propertyValue, valueType, comparatorValue, value, fieldValue])

  // Define a mapping from property values to their types.

  useEffect(() => {
    const type = propertyTypes[propertyValue]
    setValueType(type || 'string')
  }, [propertyValue])

  const determineAndReturnComparator = (valueType: string) => {
    if (valueType === 'string') {
      return stringComparator
    } else if (valueType === 'date') {
      return dateComparator
    } else if (valueType === 'number') {
      return numberComparator
    } else if (valueType === 'string[]') {
      return stringArrayComparator
    }
  }

  return (
    <div className="grid grid-cols-12 gap-2 relative w-full">
      <div className="col-span-4 ">
        <ModularComboBox
          TriggerButton={() => (
            <button className="combobox-button ">
              <span
                className={`w-[150px]  font-medium ${
                  propertyValue === 'Select property'
                    ? 'text-background-accent/60 dark:text-foreground/60'
                    : 'text-gray-500 dark:text-foreground'
                }  flex items-center`}
              >
                <p className="truncate">{propertyValue}</p>
              </span>
            </button>
          )}
          CommandItems={({ closeComboBox }) => {
            return (
              <CommandGroup>
                {initialPropertyValues
                  ?.filter((item) => item !== propertyValue)
                  ?.map((value) => (
                    <CommandItem
                      value={value}
                      key={value}
                      onSelect={() => {
                        setPropertyValue(value)
                        setFieldValue(fieldPaths[value as keyof typeof fieldPaths])
                        if (propertyTypes[value] !== valueType) {
                          setComparatorValue('Select filter')
                          setValue('')
                        }
                        closeComboBox()
                      }}
                    >
                      {value}
                    </CommandItem>
                  ))}
              </CommandGroup>
            )
          }}
          popoverContentProps={{
            align: 'start',
          }}
          allowNewCreation={false}
          searchableDisplayName="property"
        />
      </div>
      {propertyValue !== 'Select property' ? (
        <div className="col-span-3">
          <ModularComboBox
            TriggerButton={() => (
              <button className="combobox-button mr-2">
                <span
                  className={` ${
                    comparatorValue === 'Select filter'
                      ? 'text-background-accent/60 dark:text-foreground/60'
                      : 'text-gray-500 dark:text-foreground'
                  } w-[115px] font-medium text-gray-500 dark:text-foreground flex items-center`}
                >
                  <p className="truncate">{comparatorValue}</p>
                </span>
              </button>
            )}
            CommandItems={({ closeComboBox }) => {
              return (
                <CommandGroup>
                  {determineAndReturnComparator(valueType)
                    ?.filter((item) => item !== comparatorValue)
                    ?.map((value) => (
                      <CommandItem
                        value={value}
                        key={value}
                        onSelect={() => {
                          setComparatorValue(value)
                          closeComboBox()
                        }}
                      >
                        {value}
                      </CommandItem>
                    ))}
                </CommandGroup>
              )
            }}
            popoverContentProps={{
              align: 'start',
            }}
            allowNewCreation={false}
            searchableDisplayName="filter"
          />
        </div>
      ) : null}

      {comparatorValue !== 'Select filter' &&
        comparatorValue !== 'is not null' &&
        comparatorValue !== 'is null' && (
          <div className="col-span-4 w-full">
            {!Array.isArray(value) ? (
              <>
                {valueType === 'string' && comparatorValue !== 'Select filter' ? (
                  <input value={value} onChange={handleTextValueChange} placeholder="Value" />
                ) : null}
                {valueType === 'number' && comparatorValue !== 'Select filter' ? (
                  <input value={value} onChange={handleTextValueChange} placeholder="Value" />
                ) : null}
                {valueType === 'date' && comparatorValue !== 'Select filter' ? (
                  <DatePicker
                    selected={value ? new Date(value) : undefined}
                    onSelect={(date) => {
                      setValue(date.toString())
                    }}
                    buttonClasses="w-full text-left justify-start"
                  />
                ) : null}
              </>
            ) : null}

            {valueType === 'string[]' && propertyValue === 'User role' ? (
              <ModularComboBox
                TriggerButton={() => (
                  <MultiselectButton
                    customBadge={
                      value &&
                      value?.length > 1 && (
                        <span className="ml-auto text-xs bg-dark-accent px-1.5 py-0.5 rounded-md inline-block">
                          +{value?.length - 1}
                        </span>
                      )
                    }
                    className={cn(
                      'combobox-button py-1.5 dark:shadow-none w-full font-medium text-gray-500 dark:text-foreground flex items-center text-sm'
                    )}
                    // icon={<UserGroupIcon className="secondary-svg mr-1.5" />}
                  >
                    {value?.length === 0 || !value
                      ? undefined
                      : `${org.roles?.find((role) => role.id === value?.[0])?.role}`}
                    {/* {data?.allowedSegmentIds && data?.allowedSegmentIds?.length > 1 && (
                <span className="ml-auto bg-dark-accent px-1.5 py-0 rounded-md inline-block">
                  +{data?.allowedSegmentIds?.length - 1}
                </span>
              )} */}
                  </MultiselectButton>
                  // <button className="combobox-button mr-2">
                  //   <span
                  //     className={` ${
                  //       comparatorValue === 'Select filter'
                  //         ? 'text-background-accent/60 dark:text-foreground/60'
                  //         : 'text-gray-500 dark:text-foreground'
                  //     } w-[115px] font-medium text-gray-500 dark:text-foreground flex items-center`}
                  //   >
                  //     <p className="truncate">{value}</p>
                  //   </span>
                  // </button>
                )}
                CommandItems={({ closeComboBox }) => {
                  return (
                    <CommandGroup>
                      {org.roles.map((role) => (
                        <CommandItem
                          key={role.id}
                          onSelect={() => {
                            let newRoleIds = value || []

                            if (!Array.isArray(newRoleIds)) {
                              newRoleIds = []
                            }

                            if (newRoleIds.includes(role.id)) {
                              newRoleIds = newRoleIds?.filter((id: string) => id !== role.id)
                            } else {
                              newRoleIds = [...newRoleIds, role.id]
                            }

                            setValue(newRoleIds)

                            // closeComboBox()
                          }}
                        >
                          {value?.includes(role.id) && (
                            <CheckIcon className="mr-1.5 secondary-svg" />
                          )}
                          {role.role}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  )
                }}
                popoverContentProps={{
                  align: 'start',
                }}
                allowNewCreation={false}
                searchableDisplayName="filter"
              />
            ) : null}
          </div>
        )}
      {propertyValue !== 'Select property' && comparatorValue !== 'Select filter' && (
        <button
          className="dashboard-secondary dark:shadow-none col-span-1"
          onClick={() => id && onRemove(id)}
        >
          <XIcon className="secondary-svg h-5 w-5" />
        </button>
      )}
    </div>
  )
}

export default SegmentItem
