import React from 'react'
import { IOrganization } from '../interfaces/IOrganization'
import { useTranslation } from 'next-i18next'
import { Button } from './radix/Button'
import { useRouter } from 'next/router'

const MainRoadmapButtons: React.FC<{
  setFilterMode: (value: React.SetStateAction<'status' | 'quarterly' | 'monthly'>) => void
  filterMode: 'status' | 'quarterly' | 'monthly'
  org: IOrganization
  generateQuarters?: any
  generateMonths?: any
  generateStatuses?: any
}> = ({ setFilterMode, filterMode, org, generateMonths, generateQuarters, generateStatuses }) => {
  const { t } = useTranslation()

  const router = useRouter()

  // Make function to update mode in url for status remove param
  const updateMode = (mode: 'status' | 'quarterly' | 'monthly') => {
    const url = new URL(router.asPath, window.location.origin)
    if (mode === 'status') {
      url.searchParams.delete('mode')
    } else {
      url.searchParams.set('mode', mode)
    }
    router.push(url.toString(), undefined, { shallow: true })
  }

  return (
    <div className="flex flex-wrap gap-2 sm:gap-4">
      <Button
        onClick={() => {
          setFilterMode('status')
          generateStatuses()
          updateMode('status')
        }}
        variant={filterMode === 'status' ? 'default' : 'outline'}
      >
        <p className="truncate">{t('status-roadmap')}</p>
      </Button>
      {!org?.settings?.onlyStatusRoadmap && (
        <>
          <Button
            onClick={() => {
              setFilterMode('quarterly')
              generateQuarters()
              updateMode('quarterly')
            }}
            variant={filterMode === 'quarterly' ? 'default' : 'outline'}
          >
            <p className="truncate">{t('quarterly-roadmap')}</p>
          </Button>
          <Button
            onClick={() => {
              setFilterMode('monthly')
              generateMonths()
              updateMode('monthly')
            }}
            variant={filterMode === 'monthly' ? 'default' : 'outline'}
          >
            <p className="truncate">{t('monthly-roadmap')}</p>
          </Button>
        </>
      )}
    </div>
  )
}

export default MainRoadmapButtons
