import { Button } from '@/components/radix/Button'
import { cn } from '@/lib'
import { CheckIcon } from '@heroicons/react/solid'
import { useState, useCallback, useMemo } from 'react'

export type LinkEditorPanelProps = {
  initialUrl?: string
  initialOpenInNewTab?: boolean
  onSetLink: (url: string, openInNewTab?: boolean) => void
}

export const useLinkEditorState = ({
  initialUrl,
  initialOpenInNewTab,
  onSetLink,
}: LinkEditorPanelProps) => {
  const [url, setUrl] = useState(initialUrl || '')
  const [openInNewTab, setOpenInNewTab] = useState(initialOpenInNewTab || false)

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value)
  }, [])

  const isValidUrl = useMemo(() => /^(\S+):(\/\/)?\S+$/.test(url), [url])

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      if (isValidUrl) {
        onSetLink(url, openInNewTab)
      }
    },
    [url, isValidUrl, openInNewTab, onSetLink]
  )

  return {
    url,
    setUrl,
    openInNewTab,
    setOpenInNewTab,
    onChange,
    handleSubmit,
    isValidUrl,
  }
}

export const LinkEditorPanel = ({
  onSetLink,
  initialOpenInNewTab,
  initialUrl,
}: LinkEditorPanelProps) => {
  const state = useLinkEditorState({ onSetLink, initialOpenInNewTab, initialUrl })

  return (
    <div
      className={cn(
        'flex rounded-md pr-1  items-center py-1',
        initialUrl && 'lighter-dropdown-background w-64'
      )}
    >
      <form onSubmit={state.handleSubmit} className="flex items-center w-full gap-2">
        <label className="flex items-center w-full gap-2 rounded-lg cursor-text">
          <input
            type="url"
            className="text-sm w-full flex-1 pl-1.5 focus:ring-0 bg-transparent dark:bg-transparent border-0 h-8 px-1.5"
            placeholder="Enter URL"
            value={state.url}
            onChange={state.onChange}
          />
        </label>

        <Button size="icon" className="h-8">
          <CheckIcon className="h-4 w-4 !text-white" />
        </Button>
      </form>
      {/* <div className="mt-3">
        <label className="flex items-center justify-start gap-2 text-sm font-medium cursor-pointer select-none">
          Open in new tab
          <Switch
            checked={state.openInNewTab}
            onCheckedChange={(checked) => state.setOpenInNewTab(checked)}
          />
        </label>
      </div> */}
    </div>
  )
}
