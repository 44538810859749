import axios, { AxiosResponse } from 'axios'
import { SWRConfig } from 'swr'
import { unstable_serialize } from 'swr/infinite'
import SeoMetaPublic from '../components/SeoMetaPublic'
import { submissionsGetKey } from '../data/submission'
import { getHTTPProtocol, redirectToLogin, redirectToRefresh } from '../lib/subdomain'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { getClientIp } from '../lib/getClientIp'
import { IOrganization } from '../interfaces/IOrganization'
import { GetServerSideProps, GetServerSidePropsContext } from 'next'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import {
  defaultSWRConfig,
  determineSSRTheme,
  getDefaultFilters,
  isValidObjectId,
  mongooseQueryStringToObject,
  objectToMongooseQueryString,
} from '@/lib/utils'
import FeedbackBoard from '@/components/FeedbackBoard'
import { updateFilters } from '@/components/FilterSyncer'
import { ISubmissionFilters } from '@/interfaces/ISubmission'
import { sortBy } from '@/components/PopularitySorter'
import { useHydrateAtoms } from 'jotai/utils'
import { submissionActiveFilterURIAtom } from '@/atoms/submissionAtom'
import jwtPackage from 'jsonwebtoken'
import DevDocsLayout from '@/components/DevDocsLayout'
import MainCenter from '@/components/docs/MainCenter'
import { sanitizeHTML } from '@/lib/contentSanitizer'
import { stripHtml } from 'string-strip-html'

function checkIfValidTimezone(timezone: string): boolean {
  try {
    Intl.DateTimeFormat(undefined, { timeZone: timezone })
    return true
  } catch (error) {
    return false
  }
}

// Function to get server date in UTC based on the requester's timezone
export function getServerDateInUTC(context: GetServerSidePropsContext) {
  const timezoneHeader = context?.req?.headers?.['x-requester-timezone']
  const timezone = Array.isArray(timezoneHeader) ? timezoneHeader[0] : timezoneHeader
  const isValidTimezone = checkIfValidTimezone(timezone || '')

  let serverDate = new Date().toISOString()

  try {
    if (timezone && isValidTimezone) {
      const zonedDate = utcToZonedTime(new Date(), timezone)
      const utcDate = zonedTimeToUtc(zonedDate, timezone)
      serverDate = utcDate.toISOString()
    }
  } catch (error) {
    console.error('Error converting timezone:', error)
    serverDate = new Date().toISOString() // Fallback to the original serverDate value
  }

  return { serverDate, timezone: isValidTimezone ? timezone : null }
}

// Adding ip to the request headers so we can use it in the backend after proxying
export const axiosGetRequest = (
  url: string,
  cookie: string = '',
  ip: string,
  jwt?: string | string[]
) => {
  const headers = {
    'x-forwarded-for': ip,
  } as any

  if (jwt) {
    headers['x-access-token'] = jwt
  }
  if (cookie) {
    headers['cookie'] = cookie
  }

  return axios.get(url, {
    headers: headers,
  })
}

export const axiosPostRequest = (
  url: string,
  data: any,
  cookie: string = '',
  ip: string,
  jwt?: string | string[]
) => {
  const headers = {
    'x-forwarded-for': ip,
    'Content-Type': 'application/json', // Ensure JSON content type for POST body
  } as any

  if (jwt) {
    headers['x-access-token'] = jwt
  }
  if (cookie) {
    headers['cookie'] = cookie
  }

  return axios.post(url, data, {
    headers: headers,
  })
}

export const identifySSOUser = (
  http: string,
  host: string | string[] | undefined,
  cookie: string = '',
  ip: string,
  jwt: string | string[] | undefined
) => {
  if (!jwt) return undefined
  if (jwt === 'undefined') return undefined
  if (jwt === 'null') return undefined

  // If jwt is array, then we need to get the first element
  if (Array.isArray(jwt)) {
    jwt = jwt?.[0]
  }

  const headers = {
    'x-forwarded-for': ip,
  } as any

  // Get the payload from the JWT token
  const decodedPayload = jwtPackage.decode(jwt) || {}

  if (jwt) {
    headers['x-access-token'] = jwt
  }

  if (cookie) {
    headers['cookie'] = cookie
  }

  if (typeof decodedPayload === 'string') return undefined

  return axios.post(
    `${http}${host}/api/v1/user/identify`,
    {
      ...decodedPayload,
      jwt,
    },
    { headers: headers }
  )
}

// Function to assign values to the fallback object for server-side rendering with SWR
export const assignValues = (
  postsRes: any,
  postsRes2: any,
  org: PromiseSettledResult<AxiosResponse<any, any>>,
  fallback: any,
  mongoURI: string
) => {
  // Check if the organization data retrieval was successful
  if (org.status === 'fulfilled') {
    // Handle the case where there are two pages of posts
    // If having problems with SSR request not matching CSR, then log the submission key here and compare to the one from the CSR request
    if (postsRes2) {
      Object.assign(fallback, {
        // Serialize the key and assign posts data for the first two pages

        [unstable_serialize((pageIndex: number, previousPageData: any) => {
          return submissionsGetKey(pageIndex, previousPageData, mongoURI)
        })]: [postsRes.value.data, postsRes2?.value?.data],
      })
    } else {
      // Handle the case with only one page of posts
      Object.assign(fallback, {
        [unstable_serialize((pageIndex: number, previousPageData: any) => {
          return submissionsGetKey(pageIndex, previousPageData, mongoURI)
        })]: [postsRes?.value?.data],
      })
    }
  }
}

// Function to get the filter value based on the default sorting order
export const getFilterValue = (defaultSortingOrder: string) => {
  if (defaultSortingOrder === 'top') {
    return 'upvotes:desc'
  } else if (defaultSortingOrder === 'trending') {
    return 'trending'
  } else {
    return 'date:desc'
  }
}

const FEATUREBASE_DOMAIN_HELP_CENTER_EXCEPTIONS = ['help.featurebase.app']

export const getDomainType = async (
  host: string,
  cookie: string | undefined,
  ip: string,
  jwt: string | undefined
) => {
  if (!host) return 'feedback'

  if (FEATUREBASE_DOMAIN_HELP_CENTER_EXCEPTIONS.includes(host)) return 'helpcenter'

  if (host.endsWith('.featurebase.app')) return 'feedback'

  const domainTypeUrl = new URL(`${getHTTPProtocol()}${host}/api/domainType`)
  domainTypeUrl.searchParams.append('host', host)
  let type: 'feedback' | 'helpcenter' = 'feedback'

  try {
    const domainType = await axiosGetRequest(domainTypeUrl.toString(), cookie, ip, jwt)
    if (domainType.data && domainType.data === 'h') {
      type = 'helpcenter'
    }
  } catch (error) {
    console.error('Error getting domain type:', error)
  }

  return type
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  // Extract host and IP from the request headers
  const host = context.req.headers['x-forwarded-host'] || context.req.headers['host']
  const ip = getClientIp(context)
  const cookie = context.req.headers.cookie
  const http = getHTTPProtocol()
  const jwt = context.query?.jwt as string | undefined
  const { serverDate, timezone } = getServerDateInUTC(context)

  let translate = {}

  if (context.locale) {
    translate = await serverSideTranslations(context.locale)
  }

  const domainType = await getDomainType(host as string, cookie, ip, jwt)

  if (domainType === 'helpcenter') {
    const fallback = {}
    console.log('Host is helpcenter, redirecting to helpcenterdetected page')
    // const hc = await axiosGetRequest(
    //   `${http}${host}/api/v1/helpcenter?withStructure=true`,
    //   cookie,
    //   ip,
    //   jwt
    // )
    const [hc, org, user] = await Promise.allSettled([
      axiosGetRequest(`${http}${host}/api/v1/helpcenter?withStructure=true`, cookie, ip, jwt),
      axiosGetRequest(`${http}${host}/api/v1/organization`, cookie, ip, jwt),
      axiosGetRequest(`${http}${host}/api/v1/user`, cookie, ip, jwt),
    ])
    if (hc.status === 'fulfilled') {
      Object.assign(fallback, { '/v1/helpcenter?withStructure=true': hc.value.data })
    }
    if (org.status === 'fulfilled') {
      Object.assign(fallback, { '/v1/organization': org.value.data })
    }
    if (user.status === 'fulfilled') {
      Object.assign(fallback, { '/v1/user': user.value.data })
    }
    if (hc.status === 'fulfilled') {
      return {
        props: {
          domainType: 'helpcenter',
          helpCenterUrlParts: {
            locale: 'en',
            subpath: '/',
            helpCenterId: hc.value.data?.helpCenterId,
            articleId: '',
          },
          helpcenter: hc.value.data,
          renderDate: serverDate,
          timezone: timezone,
          queryParams: context.query,
          fallback,
          docsData: hc.value.data,
          ...translate,
        },
      }
    }
  }
  const boardParam = context.query?.b

  if (host) {
    const host = context.req.headers['x-forwarded-host'] || context.req.headers['host']
    const subdomain = (host as string).split('.')[0]
    if (subdomain === 'auth') {
      return redirectToLogin
    } else if (subdomain === 'developers') {
      return {
        redirect: {
          destination: 'https://help.featurebase.app/collections/8270391-developers',
          permanent: false,
        },
      }
    }
  }

  // We usec to enable linking to boards with a more beautiful name, this behaviour is supported under a different route
  if (boardParam) {
    if (!isValidObjectId(boardParam as string)) {
      return {
        redirect: { destination: '/c/' + boardParam, permanent: false },
      }
    }
  }

  // We restore the advanced filters in a form we can use by taking the url
  // Advanced filters are filters like boards, tags, status, etc.
  const filters = mongooseQueryStringToObject(
    context?.req?.url ? decodeURIComponent(context?.req?.url?.split('?')[1]) : ''
  )

  // We update the filters with the default filters that we use for the public board
  const combinedFilters = updateFilters(
    { limit: 10, advancedFilters: [] } as ISubmissionFilters,
    filters
  )

  const defaultFiltersForPublicBoardForSSR = {
    sortBy: combinedFilters.sortBy,
    inReview: combinedFilters.inReview,
    limit: 10,
    includePinned: !combinedFilters.advancedFilters?.some((item) => item.type === 'b')
      ? true
      : false,
  }

  // We combine the advanced filters to a mongo query string, this now can be used to query the backend in a way the public board needs
  const mongoURI = objectToMongooseQueryString(
    combinedFilters.advancedFilters,
    defaultFiltersForPublicBoardForSSR
  ).backendQuery

  const [postsRes, postsRes2, org, user, publicProfilePreview, identifiedUser] =
    await Promise.allSettled([
      axiosGetRequest(
        `${http}${host}/api/v1/submission/?page=1&ssr=true&` + mongoURI,
        cookie,
        ip,
        jwt
      ),
      axiosGetRequest(
        `${http}${host}/api/v1/submission/?page=2&ssr=true&` + mongoURI,
        cookie,
        ip,
        jwt
      ),
      axiosGetRequest(`${http}${host}/api/v1/organization`, cookie, ip, jwt),
      axiosGetRequest(`${http}${host}/api/v1/user`, cookie, ip, jwt),
      axiosGetRequest(`${http}${host}/api/v1/user/publicProfilePreview`, cookie, ip, jwt),
      identifySSOUser(http, host, cookie, ip, jwt),
    ])

  const fallback = {}

  let initialFiltersForFrontend = null
  let backendQueryString = ''

  // If we have all the data we need, we can update the
  if (
    org.status === 'fulfilled' &&
    postsRes.status === 'fulfilled' &&
    postsRes2.status === 'fulfilled'
  ) {
    const orgData = org?.value.data as IOrganization

    // Now that we have the organization data, we can update the filters with the default filters that we use for the public board
    initialFiltersForFrontend = updateFilters(
      getDefaultFilters(orgData),
      filters,
      getDefaultFilters(orgData)
    )

    backendQueryString = objectToMongooseQueryString(
      initialFiltersForFrontend.advancedFilters,
      {
        sortBy: initialFiltersForFrontend.sortBy,
        inReview: initialFiltersForFrontend.inReview,
        includePinned: !initialFiltersForFrontend.advancedFilters?.some((item) => item.type === 'b')
          ? sortBy[orgData?.settings?.defaultSortingOrder || 'recent'] ===
            initialFiltersForFrontend.sortBy
            ? true
            : false
          : false,
        q: initialFiltersForFrontend.q,
      },
      getDefaultFilters(orgData),
      orgData,
      orgData?.settings?.hideCompletedAndCanceled
    ).backendQuery

    // We assign the values for useSWR so we don't have to fetch the data again
    assignValues(postsRes, postsRes2, org, fallback, backendQueryString)
  }

  if (org.status === 'fulfilled') {
    if (org.value.data?.settings?.hideFeedbackBoard) {
      if (org.value.data?.settings?.hideRoadmap) {
        return {
          redirect: {
            destination: '/changelog',
            permanent: false,
          },
        }
      } else {
        return {
          redirect: {
            destination: '/roadmap',
            permanent: false,
          },
        }
      }
    }

    Object.assign(fallback, { '/v1/organization': org.value.data })
  } else {
    if (host) {
      // Redirect to login or developers page if the subdomain is auth or developers
      const host = context.req.headers['x-forwarded-host'] || context.req.headers['host']
      const subdomain = (host as string).split('.')[0]
      if (subdomain === 'auth') {
        return redirectToLogin
      } else if (subdomain === 'developers') {
        return {
          redirect: {
            destination: 'https://help.featurebase.app/collections/8270391-developers',
            permanent: false,
          },
        }
      }
    }

    return redirectToRefresh(context.req, '/')
  }

  if (user.status === 'fulfilled') {
    Object.assign(fallback, { '/v1/user': user.value.data })
  }

  if (publicProfilePreview.status === 'fulfilled') {
    Object.assign(fallback, { '/v1/user/publicProfilePreview': publicProfilePreview.value.data })
  }

  if (identifiedUser?.status === 'fulfilled') {
    if (identifiedUser?.value?.data?.user) {
      Object.assign(fallback, { '/v1/user': identifiedUser.value.data.user })
    }
  }

  return {
    props: {
      fallback,
      defaultTheme: determineSSRTheme(org?.value?.data, context),
      renderDate: serverDate,
      timezone: timezone,
      initialFilters: JSON.stringify(initialFiltersForFrontend),
      backendQueryString: backendQueryString,
      org: org.value.data,
      queryParams: context.query,
      ...translate,
    },
  }
}

const IndexPage: React.FC<{
  fallback: any
  renderDate?: Date
  initialFilters?: string
  org: IOrganization
  backendQueryString?: string
  timezone?: string
  domainType?: 'feedback' | 'helpcenter'
  docsData?: any
}> = ({
  fallback,
  renderDate,
  initialFilters,
  org,
  backendQueryString,
  timezone,
  docsData,
  domainType,
}) => {
  const { t } = useTranslation()
  useHydrateAtoms([[submissionActiveFilterURIAtom, backendQueryString]])

  const pageTitle =
    org?.structure?.board?.mainFeedbackButtonText &&
    org?.structure?.board?.mainFeedbackButtonText !== 'Feedback'
      ? org?.structure?.board?.mainFeedbackButtonText
      : t('feedback')

  return (
    <SWRConfig value={{ fallback, ...defaultSWRConfig }}>
      {domainType === 'helpcenter' ? (
        <DevDocsLayout hideMinimap={true}>
          {/* <Spotlight> */}
          <MainCenter docsData={docsData} />
          {/* </Spotlight> */}
        </DevDocsLayout>
      ) : (
        <>
          <SeoMetaPublic
            page={pageTitle}
            imageUrl={org?.ogImage ? org?.ogImage : undefined}
            ogImageProps={
              (org &&
                !org.settings.private && {
                  company: {
                    name: org?.displayName,
                    logo: org?.picture,
                    themeColor: org?.color,
                    themeLinePosition: 'bottom',
                  },
                  title: org?.structure?.CTASection?.title
                    ? org.structure.CTASection.title
                    : t('have-something-to-say'),
                  description: sanitizeHTML(
                    org.structure?.CTASection?.content
                      ? stripHtml(org.structure?.CTASection?.content)?.result
                      : t(
                          'tell-org-displayname-how-they-could-make-the-product-more-useful-to-you',
                          {
                            displayName: org.displayName,
                          }
                        ),
                    false,
                    false,
                    false
                  ),
                  category: pageTitle,
                  type: 'root',
                }) ||
              undefined
            }
          />

          <FeedbackBoard
            initialFilters={JSON.parse(initialFilters || '{}') as ISubmissionFilters}
            renderDate={renderDate}
            timezone={timezone}
          />
        </>
      )}
    </SWRConfig>
  )
}

export default IndexPage
