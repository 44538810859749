import React, { useEffect } from 'react'
import { Label } from './radix/Label'
import SegmentItem from './SegmentItem'
import { ArrowLeftIcon, TrashIcon } from '@heroicons/react/solid'
import { toast } from 'react-hot-toast'
import { updateUserSegment } from '../../network/lib/organization'
import { KeyedMutator } from 'swr'
import ObjectID from 'bson-objectid'
import ConfirmationModal from './ConfirmationModal'

interface UserSegmentViewProps {
  selectedSegment: {
    _id: string
    name: string
    rules: {
      _id: string
      name?: string
      field?: string
      fieldType?: string
      value?: string | string[] | undefined
      rule?: string
    }[]
  }
  setSelectedSegment: React.Dispatch<
    React.SetStateAction<{
      _id: string
      name: string
      rules: {
        _id: string
        name?: string
        field?: string
        fieldType?: string
        value?: string | string[] | undefined
        rule?: string
      }[]
    }>
  >
  allData:
    | {
        _id: string
        name: string
        rules: {
          _id: string
          name?: string
          field?: string
          fieldType?: string
          value?: string | string[] | undefined
          rule?: string
        }[]
      }[]
    | undefined // Allowing `undefined` here.
  setPage: React.Dispatch<React.SetStateAction<string>>
  mutateCurrentOrg: KeyedMutator<any>
}

const UserSegmentView: React.FC<UserSegmentViewProps> = ({
  selectedSegment,
  setSelectedSegment,
  allData,
  setPage,
  mutateCurrentOrg,
}) => {
  const [openDeleteSegment, setOpenDeleteSegment] = React.useState(false)

  const handleRuleChange = (
    index: number,
    rule: {
      _id: string
      name?: string
      field?: string
      fieldType?: string
      value?: string | string[] | undefined
      rule?: string
    }
  ) => {
    setSelectedSegment((prevState) => {
      let rules = [...prevState.rules]
      rules[index] = rule
      return {
        ...prevState,
        rules,
      }
    })
  }

  useEffect(() => {
    const allRulesFilled = selectedSegment.rules.every(
      (rule) =>
        rule.field &&
        rule.rule &&
        ((rule.rule !== 'is not null' && rule.rule !== 'is null' && rule.value) ||
          rule.rule === 'is not null' ||
          rule.rule === 'is null')
    )

    if (allRulesFilled) {
      setSelectedSegment({
        ...selectedSegment,
        rules: [...selectedSegment.rules, { _id: new ObjectID().toString(), rule: '' }], // Add a new empty rule with a unique id.
      })
    }
  }, [selectedSegment])

  const checkStringType = (value: string | string[]) => {
    if (Array.isArray(value)) {
      return 'array'
    }

    // Check if the value is a valid number
    if (!isNaN(Number(value))) {
      return 'number'
    }

    // Check if the value is a valid date string
    if (!isNaN(Date.parse(value))) {
      return 'date'
    }

    // Default to 'string' if no other type matches
    return 'string'
  }

  const areSegmentsValid = () => {
    let rules = selectedSegment.rules.filter((rule) => rule.name !== 'Select property')

    // Validate that every rule has a field, rule, and if rule is not "is not null" or "is null" then a value
    for (let rule of rules) {
      if (!rule.field || !rule.rule) {
        toast.error('Please complete all fields for every rule before saving.')
        return false
      }
      if (rule.rule !== 'is not null' && rule.rule !== 'is null' && !rule.value) {
        toast.error(
          'Please provide a value for every rule where rule is not "is not null" or "is null".'
        )
        return false
      }

      // Use zod to validate the value is the correct type.
      if (rule.rule !== 'is not null' && rule.rule !== 'is null') {
        const type = checkStringType(rule?.value || '')
        if (rule.fieldType === 'number' && type !== 'number') {
          toast.error('Please provide a number for the value.')
          return false
        }

        if (type === 'array' && rule?.value?.length === 0) {
          toast.error('Please provide a value for the rule.')
          return false
        }
      }
    }
    return true
  }

  const handleSave = () => {
    // Validate that every rule has a field, rule, and if rule is not "is not null" or "is null" then a value
    let rules = selectedSegment.rules.filter((rule) => rule.name !== 'Select property')

    if (!areSegmentsValid()) {
      return
    }

    const isNewSegment = allData?.find((segment) => segment['_id'] === selectedSegment['_id'])

    let newAllData = allData ? [...allData] : []

    const newSelectedSegment = {
      ...selectedSegment,
      rules: rules.map((rule) => ({
        // Using rules instead of selectedSegment.rules
        _id: rule['_id'],
        name: rule.name || '',
        field: rule.field || '',
        fieldType: rule.fieldType || '',
        value: rule.value || '',
        rule: rule.rule || '',
      })),
    }

    if (isNewSegment === undefined) {
      newAllData.push(newSelectedSegment)
    } else {
      newAllData = newAllData?.map((segment) => {
        if (segment['_id'] === selectedSegment['_id']) {
          return newSelectedSegment
        }
        return segment
      })
    }

    updateUserSegment(newAllData)
      .then((res) => {
        mutateCurrentOrg()
        if (res.status === 200) {
          toast.success('Segment saved successfully.')
        } else {
          toast.error('Error saving segment.')
        }
      })
      .catch((err) => {
        toast.error('Error saving segment.')
      })

    // Dispatch save action here.
    // saveSegment({ ...selectedSegment, rules: rules });
  }

  const handleRemoveRule = (id: string) => {
    setSelectedSegment((prevState) => ({
      ...prevState,
      rules: prevState.rules.filter((rule) => rule['_id'] !== id),
    }))
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSegment({
      ...selectedSegment,
      name: e.target.value,
    })
  }

  const handleRemoveSegment = () => {
    const newAllData = allData?.filter((segment) => segment['_id'] !== selectedSegment['_id']) || []
    updateUserSegment(newAllData)
      .then((res) => {
        mutateCurrentOrg()

        if (res.status === 200) {
          setPage('main')

          toast.success('Segment removed successfully.')
        } else {
          toast.error('Error removing segment.')
        }
      })
      .catch((err) => {
        toast.error('Error removing segment.')
      })
  }

  return (
    <div>
      <h2 className="flex items-center gap-2 text-lg font-medium text-gray-600 dark:text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6 mb-1 text-gray-200 dark:text-foreground/80"
        >
          <path
            fillRule="evenodd"
            d="M2.25 13.5a8.25 8.25 0 018.25-8.25.75.75 0 01.75.75v6.75H18a.75.75 0 01.75.75 8.25 8.25 0 01-16.5 0z"
            clipRule="evenodd"
          />
          <path
            fillRule="evenodd"
            className="text-accent"
            d="M12.75 3a.75.75 0 01.75-.75 8.25 8.25 0 018.25 8.25.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75V3z"
            clipRule="evenodd"
            fill="currentColor"
          />
        </svg>
        Create segment
      </h2>
      <div className="mt-1.5">
        <Label htmlFor="segment-name">Segment name</Label>
        <input
          id="segment-name"
          className="w-1/2 mt-1"
          type="text"
          onChange={onChange}
          value={selectedSegment.name}
        />
      </div>
      <div className="mt-3">
        <Label htmlFor="segment-name">Rules</Label>
        <div className="-mt-2 divide-y divide-gray-100 dark:divide-border">
          {selectedSegment.rules.map((rule, index) => {
            return (
              <div className="mt-1.5 pt-1.5" key={rule['_id']}>
                <SegmentItem
                  onRemove={handleRemoveRule}
                  rule={rule}
                  index={index}
                  onChange={handleRuleChange}
                />
              </div>
            )
          })}

          {/* {selectedSegment.rules.length > 0 && (
            <button
              onClick={addNewRule}
              className="w-[170px] combobox-button mt-3 text-gray-400/60 dark:text-foreground/90"
            >
              <PlusCircleIcon className="secondary-svg mr-1.5 " /> New rule
            </button>
          )} */}
        </div>
      </div>
      <ConfirmationModal
        callBack={handleRemoveSegment}
        title="Delete segment"
        description="Are you sure you want to delete this segment?"
        open={openDeleteSegment}
        setOpen={setOpenDeleteSegment}
        buttonTxt="Delete segment"
      />
      <div className="flex flex-wrap items-center justify-between gap-2 mt-6">
        <div className="flex gap-2">
          <button onClick={() => setPage('main')} className="dashboard-secondary">
            <ArrowLeftIcon className="secondary-svg" />
          </button>
          <button onClick={() => setOpenDeleteSegment(true)} className="dashboard-secondary">
            <TrashIcon className="secondary-svg mr-1.5" /> Delete
          </button>
        </div>
        <div className="flex gap-2">
          <button onClick={handleSave} className="dashboard-primary ">
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default UserSegmentView
