import React, { ChangeEvent, useCallback, useRef } from 'react'
import { CodeIcon, LinkIcon } from '@heroicons/react/solid'
import { addYoutubeVideo, cn } from '@/lib/utils'
// @ts-ignore
import { UploadFn } from 'novel/plugins'
import { Editor } from '@tiptap/react'

const EditorMenu: React.FC<{ editor: Editor; uploadFn: UploadFn; changelog?: boolean }> = ({
  uploadFn,
  changelog,
  editor,
}) => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null)
  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  // const { editor } = useEditor()

  const handleFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (!editor) return null

    const file = event.target.files?.[0]

    if (file) {
      const pos = editor.view.state.selection.from
      uploadFn(file, editor.view, pos)
    }
  }

  const setLink = useCallback(() => {
    if (!editor) return null

    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
  }, [editor])

  if (!editor) return null

  return (
    <div className={changelog ? 'flex items-center justify-center  sticky bottom-8 w-full' : ''}>
      <div
        className={
          changelog
            ? 'border  sticky bottom-8 p-2 bg-gray-50 dark:bg-secondary rounded-md border-gray-100 dark:border-border'
            : 'mt-4 '
        }
      >
        <div className="">
          <div className="flex flex-wrap items-center w-full mx-auto">
            <button
              tabIndex={-1}
              onClick={handleClick}
              className={`bg-transparent editor-icon-btn shadow-none hover:bg-gray-100  dark:hover:bg-border rounded floating-btn p-px focus:outline-none mr-4 mt-2 sm:mt-0 "
            }`}
            >
              <svg
                className="h-[18px] w-[18px]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="15" y1="8" x2="15.01" y2="8" />
                <rect x="4" y="4" width="16" height="16" rx="3" />
                <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
                <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
              </svg>
            </button>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleFile}
              style={{ display: 'none' }}
            />

            {/* Bold */}

            <button
              tabIndex={-1}
              // @ts-ignore
              onClick={() => editor.chain().focus().toggleBold().run()}
              className={`bg-transparent editor-icon-btn hover:bg-gray-100 dark:hover:bg-border text-background-accent p-px focus:outline-none mr-4 mt-2 sm:mt-0 shadow-none  ${
                editor.isActive('bold') &&
                'bg-background-accent/30 dark:bg-border hover:bg-background-accent/40 dark:hover:bg-border dark:text-white dark:hover:text-white'
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="h-[18px] w-[18px]"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="currentColor"
                  d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z"
                />
              </svg>
            </button>

            {/* Italic */}
            <button
              tabIndex={-1}
              // @ts-ignore
              onClick={() => editor.chain().focus().toggleItalic().run()}
              className={`bg-transparent hidden sm:block editor-icon-btn hover:bg-gray-100 dark:hover:bg-border text-background-accent p-px focus:outline-none mr-4 mt-2 sm:mt-0 shadow-none  ${
                editor.isActive('italic') &&
                '  bg-background-accent/30 dark:bg-border hover:bg-background-accent/40 dark:hover:bg-border dark:text-white dark:hover:text-white'
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="h-[18px] w-[18px]"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="currentColor"
                  d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z"
                />
              </svg>
            </button>

            {/* Heading 1 */}

            {/* Heading 2 */}
            <button
              tabIndex={-1}
              // @ts-ignore
              onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
              className={`bg-transparent hidden sm:block editor-icon-btn hover:bg-gray-100 dark:hover:bg-border text-background-accent p-px focus:outline-none mr-4 mt-2 sm:mt-0 shadow-none  ${
                editor.isActive('heading', { level: 2 }) &&
                '  bg-background-accent/30 dark:bg-border hover:bg-background-accent/40 dark:hover:bg-border dark:text-white dark:hover:text-white'
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                viewBox="0 0 24 24"
                strokeWidth="1.8"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M17 12a2 2 0 1 1 4 0c0 .591 -.417 1.318 -.816 1.858l-3.184 4.143l4 0" />
                <path d="M4 6v12" />
                <path d="M12 6v12" />
                <path d="M11 18h2" />
                <path d="M3 18h2" />
                <path d="M4 12h8" />
                <path d="M3 6h2" />
                <path d="M11 6h2" />
              </svg>
            </button>
            {changelog && (
              <button
                // @ts-ignore
                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                className={`bg-transparent hidden sm:block editor-icon-btn hover:bg-gray-100 dark:hover:bg-border text-background-accent p-px focus:outline-none mr-4 mt-2 sm:mt-0 shadow-none  ${
                  editor.isActive('heading', { level: 3 }) &&
                  '  bg-background-accent/30 dark:bg-border hover:bg-background-accent/40 dark:hover:bg-border dark:text-white dark:hover:text-white'
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                  strokeWidth="1.8"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M19 14a2 2 0 1 0 -2 -2" />
                  <path d="M17 16a2 2 0 1 0 2 -2" />
                  <path d="M4 6v12" />
                  <path d="M12 6v12" />
                  <path d="M11 18h2" />
                  <path d="M3 18h2" />
                  <path d="M4 12h8" />
                  <path d="M3 6h2" />
                  <path d="M11 6h2" />
                </svg>
              </button>
            )}

            {/* Bullet list */}
            <button
              tabIndex={-1}
              // @ts-ignore
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              className={`bg-transparent editor-icon-btn hover:bg-gray-100 dark:hover:bg-border text-background-accent p-px focus:outline-none mr-4 mt-2 sm:mt-0 shadow-none  ${
                editor.isActive('bulletList') &&
                '  bg-background-accent/30 dark:bg-border hover:bg-background-accent/40 dark:hover:bg-border dark:text-white dark:hover:text-white'
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="h-[18px] w-[18px]"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="currentColor"
                  d="M8 4h13v2H8V4zM4.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 6.9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
                />
              </svg>
            </button>

            {/* Bullet list */}
            <button
              tabIndex={-1}
              // @ts-ignore
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              className={`bg-transparent hidden sm:block editor-icon-btn hover:bg-gray-100 dark:hover:bg-border text-background-accent p-px focus:outline-none mr-4 mt-2 sm:mt-0 shadow-none  ${
                editor.isActive('orderedList') &&
                '  bg-background-accent/30 dark:bg-border hover:bg-background-accent/40 dark:hover:bg-border dark:text-white dark:hover:text-white'
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="h-[18px] w-[18px]"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="currentColor"
                  d="M8 4h13v2H8V4zM5 3v3h1v1H3V6h1V4H3V3h2zM3 14v-2.5h2V11H3v-1h3v2.5H4v.5h2v1H3zm2 5.5H3v-1h2V18H3v-1h3v4H3v-1h2v-.5zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
                />
              </svg>
            </button>

            {/* Bullet list */}
            {/* <button
            onClick={() => editor.chain().focus().unsetAllMarks().run()}
            className={`bg-transparent hover:bg-gray-100 dark:hover:bg-secondary text-background-accent p-px focus:outline-none mr-4 mt-2 sm:mt-0 shadow-none`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="h-[18px] w-[18px]"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="currentColor"
                d="M12.651 14.065L11.605 20H9.574l1.35-7.661-7.41-7.41L4.93 3.515 20.485 19.07l-1.414 1.414-6.42-6.42zm-.878-6.535l.27-1.53h-1.8l-2-2H20v2h-5.927L13.5 9.257 11.773 7.53z"
              />
            </svg>
          </button> */}

            {/* Undo */}
            {/* <button
              onClick={() => editor.chain().focus().undo().run()}
              className={`bg-transparent  editor-icon-btn hover:bg-gray-100 dark:hover:bg-border text-background-accent p-px focus:outline-none mr-4 mt-2 sm:mt-0 shadow-none`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="h-[18px] w-[18px]"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="currentColor"
                  d="M5.828 7l2.536 2.536L6.95 10.95 2 6l4.95-4.95 1.414 1.414L5.828 5H13a8 8 0 1 1 0 16H4v-2h9a6 6 0 1 0 0-12H5.828z"
                />
              </svg>
            </button> */}
            <button
              tabIndex={-1}
              onClick={() => setLink()}
              className={cn(
                `bg-transparent  editor-icon-btn hover:bg-gray-100 dark:hover:bg-border text-background-accent p-px focus:outline-none mr-4 mt-2 sm:mt-0 shadow-none`,
                editor.isActive('link') &&
                  '  bg-background-accent/20 dark:bg-border hover:bg-background-accent/40 dark:hover:bg-border dark:text-white dark:hover:text-white'
              )}
            >
              <LinkIcon className="h-[18px] w-[18px]" />
            </button>
            {/* Redo */}
            <button
              tabIndex={-1}
              onClick={() =>
                editor.isActive('code')
                  ? // @ts-ignore
                    editor.chain().focus().unsetCode().run()
                  : // @ts-ignore
                    editor.chain().focus().setCodeBlock().run()
              }
              className={`bg-transparent  editor-icon-btn hidden lg:block hover:bg-gray-100 dark:hover:bg-border text-background-accent p-px focus:outline-none mr-4 mt-2 sm:mt-0 shadow-none`}
            >
              <CodeIcon className="h-[18px] w-[18px]" />
            </button>
            <button
              tabIndex={-1}
              onClick={() => addYoutubeVideo(editor)}
              className={`bg-transparent hidden editor-icon-btn -ml-0.5 w-6 lg:flex items-center justify-center h-6 hover:bg-gray-100 dark:hover:bg-border text-background-accent/80 p-px focus:outline-none mt-2 sm:mt-0 shadow-none`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-[24px] w-[24px]"
                viewBox="-35.20005 -41.33325 305.0671 247.9995"
              >
                <path
                  d="M229.763 25.817c-2.699-10.162-10.65-18.165-20.748-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.553 7.652 7.6 15.655 4.903 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.903 56.85C7.6 149.68 15.553 157.681 25.65 160.4c18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.682-4.934c10.098-2.718 18.049-10.72 20.748-20.882 4.904-18.421 4.904-56.85 4.904-56.85s0-38.431-4.904-56.85"
                  fill="currentColor"
                />
                <path
                  d="M93.333 117.559l61.333-34.89-61.333-34.894z"
                  className="text-white dark:text-foreground"
                  fill="currentColor"
                />
              </svg>
            </button>
            {/* wip */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditorMenu
