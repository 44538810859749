import { IHelpCenterArticleDocument } from '@/interfaces/IHelpCenter'
import React, { useMemo, useState } from 'react'
import { IconList, IconPickerItem } from '../iconpicker'
import { cn } from '@/lib'
import SVG from 'react-inlinesvg'
import DOMPurify from 'dompurify'
import sanitizeUrl from '@/lib/sanitizeUrl'

const FeaturedIcon: React.FC<{
  icon: IHelpCenterArticleDocument['icon']
  small?: boolean
  large?: boolean
}> = ({ icon, small, large }) => {
  const [isLoading, setIsLoading] = useState(true)
  const sizeClass = large ? 'h-12 w-12' : small ? '!h-4 !w-4' : '!h-5 !w-5'

  const sanitizedIconUrl = useMemo(() => {
    if (typeof icon?.value !== 'string') return null

    try {
      return sanitizeUrl(icon?.value)
    } catch (error) {
      console.error('Invalid URL:', error)
    }
    return null
  }, [icon?.value])

  const renderIcon = () => {
    switch (icon?.type) {
      case 'emoji':
        return (
          <span className={cn(large ? 'text-4xl' : small ? 'text-sm' : 'text-base')}>
            {icon.value}
          </span>
        )
      case 'predefined':
        return <IconPickerItem icon={icon.value as IconList} size={large ? 12 : small ? 4 : 5} />
      case 'external':
        if (typeof icon.value === 'string' && icon.value.toLowerCase().endsWith('.svg')) {
          if (!sanitizedIconUrl) return null

          return (
            <>
              {isLoading && (
                <div
                  className={cn(
                    sizeClass,
                    'animate-pulse bg-gray-50 dark:bg-secondary rounded-full'
                  )}
                />
              )}
              <SVG
                src={sanitizedIconUrl}
                className={cn(sizeClass, 'aspect-auto', { hidden: isLoading })}
                preProcessor={(code) => {
                  const cleanSvg = DOMPurify.sanitize(code, { USE_PROFILES: { svg: true } })

                  const parser = new DOMParser()
                  const doc = parser.parseFromString(cleanSvg, 'image/svg+xml')
                  const svg = doc.querySelector('svg')
                  if (svg) {
                    // Sanitize and set attributes
                    const safeColor = DOMPurify.sanitize('currentColor')
                    svg.setAttribute('fill', safeColor)
                    svg.setAttribute('stroke', safeColor)
                    svg.querySelectorAll('*').forEach((el) => {
                      el.setAttribute('fill', safeColor)
                      el.setAttribute('stroke', safeColor)
                    })
                  }
                  // Serialize the modified document back to a string
                  const serializedSvg = new XMLSerializer().serializeToString(doc)

                  // Perform a final sanitization on the serialized SVG string
                  return DOMPurify.sanitize(serializedSvg, { USE_PROFILES: { svg: true } })
                }}
                onLoad={() => setIsLoading(false)}
                onError={(error) => {
                  console.error('Error loading SVG:', error)
                  setIsLoading(false)
                }}
              />
            </>
          )
        } else {
          if (!sanitizedIconUrl) return null
          return (
            <img
              src={sanitizedIconUrl}
              alt="Custom icon"
              className={cn(sizeClass, 'aspect-auto object-contain')}
            />
          )
        }
      default:
        return null
    }
  }

  return (
    <div role="img" aria-label="Featured icon">
      {renderIcon()}
    </div>
  )
}

export default FeaturedIcon
