import { type EditorState, Plugin, PluginKey } from '@tiptap/pm/state'
import { Decoration, DecorationSet, type EditorView } from '@tiptap/pm/view'

const uploadKey = new PluginKey('upload-image')

export const UploadImagesPlugin = ({ imageClass }: { imageClass: string }) =>
  new Plugin({
    key: uploadKey,
    state: {
      init() {
        return DecorationSet.empty
      },
      apply(tr, set) {
        set = set.map(tr.mapping, tr.doc)
        const action = tr.getMeta(uploadKey)
        if (action?.add) {
          const { id, pos, src } = action.add

          const placeholder = document.createElement('div')
          placeholder.setAttribute('class', 'img-placeholder')
          const image = document.createElement('img')
          image.setAttribute('class', imageClass)
          image.src = src
          placeholder.appendChild(image)
          const deco = Decoration.widget(pos + 1, placeholder, {
            id,
          })
          set = set.add(tr.doc, [deco])
        } else if (action?.remove) {
          const decos = set.find(undefined, undefined, (spec) => spec.id === action.remove.id)
          set = set.remove(decos)
        }
        return set
      },
    },
    props: {
      decorations(state) {
        return this.getState(state)
      },
    },
  })

function findPlaceholder(state: EditorState, id: {}) {
  const decos = uploadKey.getState(state) as DecorationSet
  const found = decos.find(undefined, undefined, (spec) => spec.id === id)
  return found.length ? found[0]?.from : null
}

export interface ImageUploadOptions {
  validateFn?: (file: File) => void
  onUpload: (file: File) => Promise<unknown>
}

export const createImageUpload =
  ({ validateFn, onUpload }: ImageUploadOptions): UploadFn =>
  (file, view, pos) => {
    const validated = validateFn?.(file)
    if (!validated) return
    const id = {} // Using object reference as a unique identifier

    const tr = view.state.tr
    if (!tr.selection.empty) tr.deleteSelection()

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      tr.setMeta(uploadKey, {
        add: {
          id,
          pos,
          src: reader.result,
        },
      })
      view.dispatch(tr)
    }

    onUpload(file).then(
      (src) => {
        const { schema, doc } = view.state
        const pos = findPlaceholder(view.state, id)

        if (pos == null) {
          console.warn('Placeholder not found, upload may have been cancelled')
          return
        }

        const imageSrc = typeof src === 'object' ? reader.result : src
        const node = schema.nodes.image?.create({ src: imageSrc })
        if (!node) {
          console.warn('Unable to create image node')
          return
        }

        // Ensure the position is within the document range
        const safePos = Math.min(pos, doc.content.size)

        const transaction = view.state.tr
          .replaceWith(safePos, safePos, node)
          .setMeta(uploadKey, { remove: { id } })

        view.dispatch(transaction)
      },
      (error) => {
        console.error('Image upload failed', error)
        const pos = findPlaceholder(view.state, id)
        if (pos != null) {
          const safePos = Math.min(pos, view.state.doc.content.size)
          const transaction = view.state.tr
            .delete(safePos, safePos)
            .setMeta(uploadKey, { remove: { id } })
          view.dispatch(transaction)
        }
      }
    )
  }

export type UploadFn = (file: File, view: EditorView, pos: number) => void

export const handleImagePaste = (view: EditorView, event: ClipboardEvent, uploadFn: UploadFn) => {
  if (event.clipboardData?.files.length) {
    event.preventDefault()
    const [file] = Array.from(event.clipboardData.files)
    const pos = view.state.selection.from
    if (file) uploadFn(file, view, pos)
    return true
  }
  return false
}

export const handleImageDrop = (
  view: EditorView,
  event: DragEvent,
  moved: boolean,
  uploadFn: UploadFn
) => {
  if (!moved && event.dataTransfer?.files.length) {
    event.preventDefault()
    const [file] = Array.from(event.dataTransfer.files)
    const coordinates = view.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    })
    if (file) uploadFn(file, view, coordinates?.pos ?? 0 - 1)
    return true
  }
  return false
}
