import { IOrganization } from '@/interfaces/IOrganization'
import { ISubmission } from '@/interfaces/ISubmission'
import { IUser } from '@/interfaces/IUser'
import { isMember } from '@/lib/acl'
import { cn } from '@/lib/utils'
import { useDraggable } from '@alissavrk/dnd-kit-core'
import { motion } from 'framer-motion'
import React, { useEffect } from 'react'

const DraggableElement: React.FC<{
  id: string
  children: any
  setActiveHoveringItem?: any
  entry?: ISubmission
  currentStatusId?: string
  user?: IUser
  org?: IOrganization
  width?: number
  index?: number
}> = ({
  id,
  children,
  setActiveHoveringItem,
  entry,
  currentStatusId,
  user,
  org,
  width = 0,
  index = 0,
}) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id,
    data: {
      entry,
      currentStautsId: currentStatusId,
    },
    disabled: width > 720 ? (user && org ? (isMember(user?.id, org) ? false : true) : true) : true,
  })

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
  }
  useEffect(() => {
    if (!isDragging) {
      return
    }

    document.body.style.cursor = 'grabbing'

    return () => {
      document.body.style.cursor = ''
    }
  }, [isDragging])

  return (
    <motion.div
      ref={setNodeRef}
      initial={{ opacity: 0 }}
      animate={{ height: isDragging ? 0 : 'auto', opacity: isDragging ? 0 : 1 }}
      transition={{
        ease: 'easeInOut',
        opacity: {
          duration: 0.2,
        },
        height: {
          duration: 0.2,
        },
      }}
      className={cn('overflow-hidden')}
      style={style}
      {...listeners}
      {...attributes}
    >
      {children}
    </motion.div>
  )
}

export default DraggableElement
