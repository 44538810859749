import { AxiosError } from 'axios'
import { useAtom } from 'jotai'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { pushIssueToJira } from '../../network/lib/organization'
import { popupNotificationsAtom } from '../atoms/notificationAtom'
import { ISubmission } from '../interfaces/ISubmission'
import InlineError from './InlineError'
import { toast } from 'react-hot-toast'
import Loader from './Loader'
import {
  IJiraProject,
  useCurrentOrganization,
  useJiraConfig,
  useJiraProjects,
} from '@/data/organization'
import ModularComboBox from './radix/ModularComboBox'
import { CommandGroup, CommandItem } from './radix/Command'
import MultiselectButton from './MultiselectButton'
import { getContentForNewPost } from './CreateNewClickupPost'

const CreateNewJiraPost: React.FC<{
  submission: ISubmission
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  callback?: () => void
}> = ({ submission, setOpen, callback }) => {
  const [issueTitle, setIssueTitle] = React.useState(submission.title)
  const { org } = useCurrentOrganization()
  // dompurify to remove html tags from content
  const [content, setContent] = useState('')
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [selectedJiraProject, setSelectedJiraProject] = React.useState<IJiraProject | undefined>()
  const [selectedJiraIssueId, setSelectedJiraIssueId] = React.useState<
    | {
        id: string
        name: string
      }
    | undefined
  >()
  const { t } = useTranslation()
  const { jiraProjects } = useJiraProjects()
  const { jiraConfig, mutateJiraConfig } = useJiraConfig()

  useEffect(() => {
    getContentForNewPost(submission, org).then(setContent)
  }, [submission, org])

  useEffect(() => {
    if (jiraProjects && jiraConfig && !selectedJiraProject && !selectedJiraIssueId) {
      const selectedProject =
        jiraProjects.find((project) => project.id === jiraConfig?.projectId) || jiraProjects[0]
      setSelectedJiraProject(selectedProject)
      setSelectedJiraIssueId(
        selectedProject?.issueTypes?.find((i) => i.id === jiraConfig?.issueTypeId)
      )
    }
  }, [jiraProjects, selectedJiraProject, jiraConfig, selectedJiraIssueId, jiraConfig])

  const pushToJira = () => {
    if (loading) return

    setLoading(true)

    pushIssueToJira(
      issueTitle,
      content,
      submission.id,
      selectedJiraProject?.id,
      selectedJiraIssueId?.id
    )
      .then((res) => {
        if (res.data.success) {
          setOpen(false)
          setLoading(false)

          callback && callback()
          toast.success('Issue pushed to Jira')
        }
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        toast.error('Could not push issue to Jira. ' + err.response?.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <p className="mt-3 mb-1 text-sm font-medium text-gray-400 dark:text-foreground">Title</p>
      <input
        value={issueTitle}
        onChange={(event) => setIssueTitle((prev) => event.target.value)}
        placeholder={t('title-of-your-post')}
      />
      <div className="flex items-center mt-3">
        <p className="text-sm font-medium text-gray-400 dark:text-foreground">{t('content')}</p>
      </div>

      <div className="mt-1">
        <textarea
          rows={4}
          value={content}
          onChange={(event) => setContent((prev) => event.target.value)}
        />
      </div>

      <div className="grid grid-cols-2 gap-3 mt-3 ">
        <div>
          <p className="mb-1 text-sm font-medium text-gray-400 dark:text-foreground">Project</p>

          <ModularComboBox
            allowNewCreation={false}
            searchableDisplayName="Jira project name"
            TriggerButton={() => <MultiselectButton>{selectedJiraProject?.name}</MultiselectButton>}
            popoverContentProps={{ className: 'w-72', align: 'start' }}
            CommandItems={({ closeComboBox }) => {
              return (
                <CommandGroup>
                  {jiraProjects?.map((project) => {
                    return (
                      <CommandItem
                        onSelect={() => {
                          setSelectedJiraProject(project)
                          setSelectedJiraIssueId(project.issueTypes[0])
                          closeComboBox()
                        }}
                        key={project.id}
                      >
                        {project.name}
                      </CommandItem>
                    )
                  })}
                </CommandGroup>
              )
            }}
          />
        </div>
        <div>
          <p className="mb-1 text-sm font-medium text-gray-400 dark:text-foreground">Issue type</p>

          <ModularComboBox
            allowNewCreation={false}
            searchableDisplayName="Jira issue type"
            TriggerButton={() => <MultiselectButton>{selectedJiraIssueId?.name}</MultiselectButton>}
            popoverContentProps={{ className: 'w-72', align: 'start' }}
            CommandItems={({ closeComboBox }) => {
              return (
                <CommandGroup>
                  {selectedJiraProject?.issueTypes?.map((issue) => {
                    return (
                      <CommandItem
                        onSelect={() => {
                          setSelectedJiraIssueId(issue)
                          closeComboBox()
                        }}
                        key={issue.id}
                      >
                        {issue.name}
                      </CommandItem>
                    )
                  })}
                </CommandGroup>
              )
            }}
          />
        </div>
      </div>
      {error && <InlineError error={error}></InlineError>}
      <div className="flex justify-end w-full pt-4">
        <button onClick={() => pushToJira()} className="dashboard-primary">
          {loading && (
            <div className="secondary-svg mr-1.5">
              {' '}
              <Loader />
            </div>
          )}
          Push to Jira
        </button>
      </div>
    </div>
  )
}

export default CreateNewJiraPost
