import { notificationAtom, notificationTypeAtom } from '@/atoms/notificationAtom'
import { useCurrentOrganization, useNotificationsWithFiltering } from '@/data/organization'
import { useAtom } from 'jotai'
import React from 'react'
import { getActualType } from './NotificationResults'
import { useRouter } from 'next/router'
import Script from 'next/script'
import CustomThemeHandler from './CustomThemeHandler'
import { useUser } from '@/data/user'
import { missingPermissionAtom } from '@/atoms/orgAtom'
import PermissionRequiredPopup from './PermissionRequiredPopup'

const BoardAndDashboardWrapper: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [notificationType, setNotificationType] = useAtom(notificationTypeAtom)
  const [notifications, setNotifications] = useAtom(notificationAtom)
  const [requiresMissingPermission, setRequiresMissingPermission] = useAtom(missingPermissionAtom)

  const { org } = useCurrentOrganization()
  const { user } = useUser()
  const router = useRouter()

  const {
    notificationResults,
    size,
    setSize,
    rawNotificationResults,
    mutateNotifications,
    totalNotificationResults,
    totalUnviewedResults,
    notificationLoading,
  } = useNotificationsWithFiltering(
    user
      ? { type: getActualType(notificationType.type, org), viewed: notificationType.viewed }
      : null,
    org
  )
  const isIframeContext = React.useMemo(() => {
    if (typeof window === 'undefined') return false
    return window.parent !== window
  }, [])
  const currentDataString = JSON.stringify(notificationResults)
  React.useEffect(() => {
    if (
      currentDataString !== JSON.stringify(notifications?.notificationResults || {}) ||
      notificationLoading !== notifications?.notificationLoading
    ) {
      //   console.log('setting this again')
      setNotifications({
        notificationResults: notificationResults || [],
        size,
        setSize,
        rawNotificationResults,
        mutateNotifications,
        totalNotificationResults,
        totalUnviewedResults,
        notificationLoading,
      })
    }
  }, [currentDataString, notificationLoading])

  React.useEffect(() => {
    // Function to handle route changes
    const handleRouteChange = (url: string) => {
      // Send the new path to the parent window
      if (window.parent !== window) {
        window.parent.postMessage(
          {
            from: 'featurebase',
            target: 'FeaturebaseEmbed',
            action: 'routeChange',
            url: url,
          },
          '*'
        ) // Adjust the target origin as needed
      }
    }
    const handleHashChange = (event: HashChangeEvent) => {
      // Send the new path to the parent window
      if (window.parent !== window) {
        window.parent.postMessage(
          {
            from: 'featurebase',
            target: 'FeaturebaseEmbed',
            action: 'routeChange',
            url: event.newURL,
          },
          '*'
        ) // Adjust the target origin as needed
      }
    }
    // Add the route change event listener
    router.events.on('routeChangeComplete', handleRouteChange)
    window.addEventListener('hashchange', handleHashChange, false)

    // Clean up the event listener on component unmount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      window.removeEventListener('hashchange', handleHashChange, false)
    }
  }, [router])
  // Update loading
  // React.useEffect(() => {
  //   if (notificationLoading !== notifications?.notificationLoading) {
  //     setNotifications({
  //       notificationResults: notifications?.notificationResults || [],
  //       size,
  //       setSize,
  //       rawNotificationResults,
  //       mutateNotifications,
  //       totalNotificationResults,
  //       totalUnviewedResults,
  //       notificationLoading,
  //     })
  //   }
  // }, [notificationLoading])

  return (
    <CustomThemeHandler>
      {isIframeContext ? <Script src="/js/iframe-resize.js"></Script> : null}
      {children}
      {requiresMissingPermission?.permission && (
        <PermissionRequiredPopup
          isOpen={requiresMissingPermission ? true : false}
          setOpen={() => setRequiresMissingPermission(undefined)}
          permission={requiresMissingPermission.permission}
        />
      )}
    </CustomThemeHandler>
  )
}

export default BoardAndDashboardWrapper
