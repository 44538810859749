import { API } from '@/lib/api'

import {
  CharacterCount,
  Color,
  Document as AdvancedDocument,
  Dropcursor,
  Figcaption,
  FileHandler,
  Focus,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalRule,
  ImageBlock,
  Link,
  Placeholder,
  Selection,
  SlashCommand,
  StarterKit,
  Table,
  TableOfContents,
  TableCell,
  TableHeader,
  TableRow,
  TextAlign,
  TextStyle,
  TrailingNode,
  Typography,
  Underline,
  // emojiSuggestion,
  Columns,
  Column,
  TaskItem,
  TaskList,
  ImageUpload,
} from './index'
import { CodeBlockLowlight } from '@tiptap/extension-code-block-lowlight'
import { TableOfContentsNode } from './TableOfContentsNode'
import { createLowlight, common } from 'lowlight'
import iframe from '@/components/tiptap/iframe'
import Mention from '@tiptap/extension-mention'
import { ReactRenderer } from '@tiptap/react'
import { MentionList } from '@/components/MentionList'
import Details from '@tiptap-pro/extension-details'
import DetailsSummary from '@tiptap-pro/extension-details-summary'
import DetailsContent from '@tiptap-pro/extension-details-content'
import CalloutExtension from './Callout/CalloutExtension'
import MulticodeExtension from './MultipleCodeBlock/MulticodeExtension'
import { Document as NormalDocument } from '@tiptap/extension-document'
import { customAlphabet } from 'nanoid'

const lowlight = createLowlight(common)

interface ExtensionKitProps {
  userId?: string
  userName?: string
  userColor?: string
}

const alphabet = '01345abcdf7okepqiszlmr682xytw'
const nanoid = customAlphabet(alphabet, 11)

const advancedExtensions = [
  TextStyle,
  FontFamily,
  TableOfContents.configure({
    getId: () => nanoid(),
  }),
  TableOfContentsNode,
  Details.configure({
    HTMLAttributes: {
      class: 'details',
    },
  }),
  MulticodeExtension,
  DetailsSummary,
  DetailsContent,
  Columns,
  Column,
  Color,
  Highlight?.configure({ multicolor: true }),
]

export const ExtensionKit = (
  instanceId: string,
  compactMode: boolean,
  staticPlaceholderRefWrapper?: any,
  changelog?: boolean
) => [
  Typography,
  TaskList,
  TaskItem?.configure({
    nested: true,
  }),
  FontSize,
  TextAlign?.extend({
    addKeyboardShortcuts() {
      return {}
    },
  })?.configure({
    types: ['heading', 'paragraph'],
  }),
  compactMode ? NormalDocument : AdvancedDocument,
  Selection,
  Heading?.configure({
    levels: [1, 2, 3, 4, 5, 6],
  }),
  ImageUpload,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  CalloutExtension,
  HorizontalRule,
  StarterKit?.configure({
    document: false,
    dropcursor: false,
    heading: false,
    horizontalRule: false,
    // history: false,
    codeBlock: false,
  }),
  // UniqueID.configure({
  //   types: ['heading', 'paragraph'],
  // generateID: () => nanoid(),
  // }),
  CodeBlockLowlight?.configure({
    lowlight,
    defaultLanguage: null,
  }),
  TrailingNode,
  Link?.configure({
    openOnClick: false,
  }),
  Underline,
  CharacterCount?.configure({ limit: 50000 }),
  ImageBlock.configure({
    allowBase64: true,
  }),
  FileHandler?.configure({
    allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
    onDrop: (currentEditor, files, pos) => {
      files.forEach(async () => {
        const url = await API.uploadImage(files)

        currentEditor.chain().setImageBlockAt({ pos, src: url }).focus().run()
      })
    },
    onPaste: (currentEditor, files) => {
      files.forEach(async () => {
        const url = await API.uploadImage(files)

        return currentEditor
          .chain()
          .setImageBlockAt({ pos: currentEditor.state.selection.anchor, src: url })
          .focus()
          .run()
      })
    },
  }),
  // Emoji?.configure({
  //   enableEmoticons: true,
  //   suggestion: emojiSuggestion,
  // }),
  Placeholder?.configure({
    includeChildren: true,
    showOnlyCurrent: false,
    // placeholder: () => '' ,
    placeholder: compactMode && !changelog ? staticPlaceholderRefWrapper?.current : () => null,
    ...(compactMode &&
      !changelog && {
        emptyNodeClass:
          'w-full h-full pointer-events-none after:text-background-accent/60 after:dark:text-foreground/60',
      }),
  }),
  SlashCommand.configure({
    compactMode,
    changelog,
  }),
  Focus,
  Figcaption,
  Dropcursor?.configure({
    width: 2,
    class: 'ProseMirror-dropcursor border-black',
  }),
  iframe,
  Mention.configure({
    HTMLAttributes: {
      class: 'dark:bg-border bg-[#E9ECF1] px-1 py-0.5 text-sm shadow-none rounded-md',
    },
    renderLabel({ options, node }) {
      return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
    },

    suggestion: {
      allowSpaces: true,
      decorationClass: 'whitespace-pre-wrap bg-gray-50 dark:bg-secondary px-1 py-0.5 rounded-md',
      render: () => {
        let reactRenderer: ReactRenderer

        return {
          onStart: (props) => {
            reactRenderer = new ReactRenderer(MentionList, {
              props: {
                ...props,
                instanceId: instanceId,
              },
              editor: props.editor,
            })
          },

          onUpdate(props) {
            // if ends with 3 spaces we exit the editor
            reactRenderer?.updateProps(props)
          },

          onKeyDown(props) {
            if (props.event.key === 'Escape') {
              reactRenderer?.destroy()
              return true
            }

            return (reactRenderer?.ref as any)?.onKeyDown(props)
          },

          onExit() {
            reactRenderer.destroy()
          },
        }
      },
    },
  }),
  ...(compactMode
    ? [
        // Mention.configure({
        //   HTMLAttributes: {
        //     class: 'dark:bg-border bg-[#E9ECF1] px-1 py-0.5 text-sm shadow-none rounded-md',
        //   },
        //   renderLabel({ options, node }) {
        //     return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
        //   },
        //   suggestion: {
        //     allowSpaces: true,
        //     decorationClass:
        //       'whitespace-pre-wrap bg-gray-50 dark:bg-secondary px-1 py-0.5 rounded-md',
        //     render: () => {
        //       let reactRenderer: ReactRenderer
        //       return {
        //         onStart: (props) => {
        //           reactRenderer = new ReactRenderer(MentionList, {
        //             props: {
        //               ...props,
        //               instanceId: instanceId,
        //             },
        //             editor: props.editor,
        //           })
        //         },
        //         onUpdate(props) {
        //           // if ends with 3 spaces we exit the editor
        //           reactRenderer?.updateProps(props)
        //         },
        //         onKeyDown(props) {
        //           if (props.event.key === 'Escape') {
        //             reactRenderer?.destroy()
        //             return true
        //           }
        //           return (reactRenderer?.ref as any)?.onKeyDown(props)
        //         },
        //         onExit() {
        //           reactRenderer.destroy()
        //         },
        //       }
        //     },
        //   },
        // }),
      ]
    : advancedExtensions),
]

export default ExtensionKit
