import { useMemo } from 'react'
import { Editor, useEditor } from '@tiptap/react'
import { ExtensionKit } from '@/components/editor/extensions/extension-kit'
// import { EditorUser } from '@/components/editor/components/BlockEditor/types'
import { useDebouncedCallback } from 'use-debounce'
import {
  handleImageDrop,
  handleImagePaste,
} from '@/components/editor/extensions/HandleNonCommandImage/regular-upload-images'
import { uploadFn } from '@/components/editor/image-upload'

declare global {
  interface Window {
    editor: Editor | null
  }
}

export const useBlockEditor = (
  instanceId: string,
  content: string,
  setNewContent: (content: string) => any,
  compactMode: boolean,
  staticPlaceholderRefWrapper?: any,
  height?: number,
  editorRef?: any,
  dontAutoFocus?: boolean,
  changelog?: boolean
) => {
  // const [collabState, setCollabState] = useState<WebSocketStatus>(WebSocketStatus.Connecting)

  const debouncedUpdates = useDebouncedCallback(async (newContent: string) => {
    setNewContent(newContent)
  }, 70)

  const editor = useEditor(
    {
      autofocus: false,
      // onCreate: ({ editor }) => {
      //   provider?.on('synced', () => {
      //     if (editor.isEmpty) {
      //       editor.commands.setContent(initialContent)
      //     }
      //   })
      // },
      onUpdate: ({ editor }) => {
        debouncedUpdates(editor.getHTML())
      },
      extensions: [
        ...ExtensionKit(instanceId, compactMode, staticPlaceholderRefWrapper, changelog),
        // Collaboration.configure({
        //   document: ydoc,
        // }),
        // CollaborationCursor.configure({
        //   provider,
        //   user: {
        //     name: randomElement(userNames),
        //     color: randomElement(userColors),
        //   },
        // }),
      ],
      editorProps: {
        handlePaste: (view, event) => handleImagePaste(view, event, uploadFn),
        handleDrop: (view, event, _slice, moved) => handleImageDrop(view, event, moved, uploadFn),
        attributes: {
          class: !compactMode || changelog ? 'installation-content' : '',
          // class: 'min-h-full min-h-',
          spellcheck: 'true',
          tabindex: dontAutoFocus ? '-1' : '0', // Add this line
          // style: `min-height: ${height}px; padding-bottom: ${changelog ? 32 : 12}px;`,
          style: `min-height: ${height ? height : 100}px;  padding-bottom: ${12}px;`,
        },
      },
      onCreate: ({ editor }) => {
        if (editorRef) {
          editorRef.current = editor
        }
      },
      onBeforeCreate({ editor }) {
        if (editorRef) {
          editorRef.current = editor
        }
      },
      content: content,
    }
    // [ydoc, provider]
  )

  // const users = useMemo(() => {
  //   if (!editor?.storage.collaborationCursor?.users) {
  //     return []
  //   }

  //   return editor.storage.collaborationCursor?.users.map((user: EditorUser) => {
  //     const names = user.name?.split(' ')
  //     const firstName = names?.[0]
  //     const lastName = names?.[names.length - 1]
  //     const initials = `${firstName?.[0] || '?'}${lastName?.[0] || '?'}`

  //     return { ...user, initials: initials.length ? initials : '?' }
  //   })
  // }, [editor?.storage.collaborationCursor?.users])

  const characterCount = editor?.storage.characterCount || { characters: () => 0, words: () => 0 }

  // useEffect(() => {
  //   provider?.on('status', (event: { status: WebSocketStatus }) => {
  //     setCollabState(event.status)
  //   })
  // }, [provider])

  return { editor, characterCount }
}
