import { useMembers, useCurrentOrganization, useFilters } from '@/data/organization'
import { ISubmissionFilters } from '@/interfaces/ISubmission'
import { XIcon } from '@heroicons/react/solid'
import React, { SetStateAction, useEffect, useMemo, useState } from 'react'
import { getAvailableFilterData } from './MainFilterDropdown'

import { postsFilterAtom } from '@/atoms/dashboardAtom'
import { cn, objectToMongooseQueryString } from '@/lib/utils'
import { useAtom } from 'jotai'
import { updateFilter } from 'network/lib/organization'
import { useRouter } from 'next/router'
import { toast } from 'sonner'
import FilterTabElement from './FilterTabElement'
import FilterTabView from './FilterTabView'
import { useUser } from '@/data/user'
import CreateSegments from './CreateSegments'
import { isMember } from '@/lib/acl'

// ActiveFilterTab component for managing and displaying active filters
const ActiveFilterTab: React.FC<{
  activeFilters: any[]
  setActiveFilters: (update: SetStateAction<ISubmissionFilters>) => void
  publicBoard?: boolean
  roadmap?: boolean
  categoryIds?: string[]
}> = ({ activeFilters, setActiveFilters, publicBoard, roadmap, categoryIds }) => {
  // Hooks for fetching organization data, members, and filters
  const { org } = useCurrentOrganization()
  const { user } = useUser()
  const { members } = useMembers(isMember(user?.id, org))
  const {
    filterData: filters,
    isDataLoading: loading,
    mutate,
    rawData,
  } = useFilters(isMember(user?.id, org))
  const [mainFilters, setMainFilters] = useAtom(postsFilterAtom)

  // State for managing filter creation and active view ID
  const [createFilter, setCreateFilter] = useState(false)
  const [activeViewId, setActiveViewId] = useState<string>('')
  const [queryViewId, setQueryViewId] = useState<string>('')
  const [createNewSegmentOpen, setCreateNewSegmentOpen] = useState(false)

  // Router for managing URL query parameters
  const router = useRouter()
  useEffect(() => {
    // Set queryViewId from the URL query parameter 'view'
    if (router.query?.view) {
      const view = router.query.view
      setQueryViewId(view?.toString())
    }
  }, [router.query])

  // Set activeViewId from queryViewId and reset queryViewId
  useEffect(() => {
    if (queryViewId && activeFilters) {
      setActiveViewId(queryViewId)
      setQueryViewId('')
    }
  }, [activeFilters, queryViewId])

  // Reset activeViewId if there are no active filters
  useEffect(() => {
    if (!activeFilters && activeViewId && !publicBoard) {
      setActiveViewId('')
    }
  }, [activeViewId, activeFilters, publicBoard])

  // Memoized filter elements to optimize rendering
  const filterEelements = useMemo(() => {
    return activeFilters?.map((filter, index) => {
      const initialFilter = getAvailableFilterData(org, members).find(
        (avfilter) => avfilter.backendValue === filter.type
      )
      if (!initialFilter) return null

      // Omit filters based on certain conditions (e.g., publicBoard, roadmap, etc.)
      if (
        publicBoard &&
        filter.type === 'b' &&
        !filter.userGenerated &&
        activeFilters.filter((filter) => filter.type === 'b').length === 1 &&
        activeFilters
          .filter((filter) => filter.type === 'b')
          .every((filter) => filter.values?.length === 1) &&
        initialFilter.backendValue === 'b'
      )
        return null

      if (
        roadmap &&
        filter.type === 'b' &&
        filter.operator === 'is not' &&
        !filter.userGenerated &&
        filter.values?.every((item: any) => categoryIds?.includes(item))
      )
        return null

      // Hide default tag for rolla board
      if (
        org.name === 'rolla' &&
        !filter.userGenerated &&
        filter.type === 't' &&
        filter?.values?.length === 1 &&
        publicBoard
      )
        return null

      return (
        <FilterTabElement
          initialFilter={initialFilter}
          roadmap={roadmap ?? false}
          setActiveFilters={setActiveFilters}
          key={filter.id}
          filter={filter}
          index={index}
          publicBoard={publicBoard}
          setCreateNewSegmentOpen={setCreateNewSegmentOpen}
        />
      )
    })
  }, [activeFilters, members, org])

  // Check if any active filter exists
  const activeFilterExists = filterEelements?.some((item) => item !== null)

  const updateCurrentSavedFilter = () => {
    if (activeViewId) {
      const view = filters?.find((filter) => filter.id === activeViewId)
      if (view) {
        updateFilter({
          description: view.description ?? '',
          filter: objectToMongooseQueryString(activeFilters, {
            sortBy: mainFilters.sortBy,
            inReview: mainFilters.inReview,
            q: mainFilters.q,
          }).backendQuery,
          isPrivate: view.isPrivate,
          title: view.title,
          id: view.id,
        })
          .then((res) => {
            if (res?.data?.filter) {
              toast.success('View successfully saved')
              mutate(
                {
                  ...rawData,
                  filters: filters.map((item) => {
                    if (item.id === activeViewId) {
                      return res.data.filter
                    } else {
                      return item
                    }
                  }),
                },
                false
              )
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message)
          })
      }
    }
  }

  return (
    <div
      className={cn(
        // Conditional styling based on whether active filters exist and the type of board
        activeFilterExists &&
          ` ${
            publicBoard || roadmap
              ? 'pb-0 pt-2.5'
              : 'p-4 bg-gray-50/30 dark:bg-secondary/30 border-b dark:border-border/60 border-gray-100/60 dashboard-padding '
          }`
      )}
    >
      {!publicBoard && !roadmap && (
        <FilterTabView
          filters={filters}
          loading={loading}
          mutate={mutate}
          activeView={activeViewId}
          activeFilters={activeFilters}
          createFilterModalOpen={createFilter}
          setCreateFilterModalOpen={setCreateFilter}
          filtersExist={activeFilterExists}
          setActiveViewId={setActiveViewId}
          mainFilters={mainFilters}
          rawData={rawData}
          setMainFilters={setMainFilters}
        />
      )}

      <div className="flex flex-wrap items-center gap-2">
        {filterEelements}
        {activeFilterExists && !publicBoard && !roadmap && (
          <div className="flex items-center gap-2 ml-auto">
            {!activeViewId && (
              <button
                onClick={() => {
                  setMainFilters({
                    sortBy: 'date:desc',
                    advancedFilters: [],
                  })
                  setActiveFilters({
                    sortBy: 'date:desc',
                    advancedFilters: [],
                  })
                }}
                className="dashboard-secondary shadow-none bg-transparent ml-auto border-transparent dark:bg-transparent dark:border-transparent py-[6px] text-gray-400 text-xs dark:shadow-none"
              >
                <XIcon className="w-5 h-5 secondary-svg" />
              </button>
            )}
            <button
              onClick={() => {
                activeViewId ? updateCurrentSavedFilter() : setCreateFilter(true)
              }}
              className="dashboard-secondary py-[6px] text-gray-400 text-xs"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="mr-1.5 seconday-svg"
              >
                <path d="M11.644 1.59a.75.75 0 01.712 0l9.75 5.25a.75.75 0 010 1.32l-9.75 5.25a.75.75 0 01-.712 0l-9.75-5.25a.75.75 0 010-1.32l9.75-5.25z" />
                <path d="M3.265 10.602l7.668 4.129a2.25 2.25 0 002.134 0l7.668-4.13 1.37.739a.75.75 0 010 1.32l-9.75 5.25a.75.75 0 01-.71 0l-9.75-5.25a.75.75 0 010-1.32l1.37-.738z" />
                <path d="M10.933 19.231l-7.668-4.13-1.37.739a.75.75 0 000 1.32l9.75 5.25c.221.12.489.12.71 0l9.75-5.25a.75.75 0 000-1.32l-1.37-.738-7.668 4.13a2.25 2.25 0 01-2.134-.001z" />
              </svg>
              {activeViewId ? 'Save changes' : 'Save filters'}
            </button>
          </div>
        )}
      </div>
      <CreateSegments open={createNewSegmentOpen} setOpen={setCreateNewSegmentOpen} />
    </div>
  )
}

export default ActiveFilterTab
