import { TextIcon } from 'lucide-react'
import React from 'react'
import { cn } from '@/lib'
import Scrollspy from '@/lib/scrollspy'
import { IHelpCenterArticleDocument } from '@/interfaces/IHelpCenter'
import { stripHtml } from 'string-strip-html'

interface TableOfContentsItem {
  tagName: string
  text: string
  id: string
  level: number
}

const extractHeaders = (html: string): TableOfContentsItem[] => {
  const headerRegex = /<(h[12])\s+id="([^"]+)"[^>]*>(.*?)<\/\1>/gi
  const headers: TableOfContentsItem[] = []
  let match

  while ((match = headerRegex.exec(html)) !== null) {
    headers.push({
      tagName: match[1],
      id: match[2],
      text: stripHtml(match[3].replace(/<[^>]+>/g, ''))?.result, // Decode HTML entities after removing nested tags
      level: parseInt(match[1].charAt(1)),
    })
  }

  return headers
}

const DocsMinimap: React.FC<{ editor?: boolean; docsData?: IHelpCenterArticleDocument }> = ({
  editor,
  docsData,
}) => {
  const tableOfContents = docsData?.body ? extractHeaders(docsData.body) : []

  if (tableOfContents.length === 0 && !editor) {
    return null
  }

  let hasPreviousH1 = false

  return (
    <aside
      className={cn(
        'mt-[8px] flex-shrink-0 hidden sm:block custom-scrollbar-stronger relative w-52 h-max ml-0'
      )}
    >
      <div className="fixed w-52">
        <p className="flex items-center text-sm font-medium">
          <TextIcon className="mr-2 secondary-svg" />
          On this page
        </p>
        <div className="mt-3 -ml-1 max-h-[calc(100dvh-120px)] custom-scrollbar overflow-auto">
          <div className="flex flex-col gap-1">
            <Scrollspy
              className="!m-0 !p-0"
              items={tableOfContents.map((item) => item.id)}
              currentClassName={
                editor
                  ? ''
                  : 'dark:text-accent dark:hover:text-accent text-primary-modified hover:text-primary-modified'
              }
            >
              {tableOfContents.map((item, index) => {
                let marginLeft: any = 0

                if (item.tagName.toLowerCase() === 'h1') {
                  hasPreviousH1 = true
                }

                if (item.tagName.toLowerCase() === 'h2' && hasPreviousH1) {
                  marginLeft = `${1 * item.level - 1}rem`
                }

                return (
                  <a
                    key={item.id}
                    href={`#${item.id}`}
                    style={{
                      marginLeft: marginLeft,
                    }}
                    className={cn(
                      'block font-medium max-w-52 line-clamp-2 p-1 text-foreground/80 hover:text-foreground main-transition rounded bg-opacity-10 text-sm transition-all'
                    )}
                  >
                    <span className="line-clamp-2">{item.text}</span>
                  </a>
                )
              })}
            </Scrollspy>
          </div>
        </div>
      </div>
    </aside>
  )
}

export default DocsMinimap
