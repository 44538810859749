import { CheckCircleIcon } from '@heroicons/react/solid'
import React from 'react'
import { tagColorData } from './AddTagModal'

const CustomColorSwatch: React.FC<{ active: string; setActive: Function }> = ({
  active,
  setActive,
}) => {
  return (
    <div>
      <p className="text-sm text-gray-500 dark:text-gray-100 mt-3">Choose color</p>
      <div className="flex gap-3 flex-wrap mt-2 ">
        {tagColorData.map((color) => (
          <div
            className="relative h-9 w-9 overflow-hidden rounded-md cursor-pointer"
            key={color.name}
            onClick={() => (active === color.color ? setActive('') : setActive(color.name))}
            aria-hidden="true"
          >
            {active === color.name && (
              <CheckCircleIcon className="h-4 w-4 absolute right-0 text-white m-0.5 top-0" />
            )}

            <div className="absolute inset-0 z-10 hover:bg-white/40 bg-white/0 main-transition highlight-white-strong"></div>
            <div className={`h-full w-full ${color.color} `}></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CustomColorSwatch
