import { useCurrentOrganization } from '@/data/organization'
import { IOrganization } from '@/interfaces/IOrganization'
import chroma from 'chroma-js'
import { useRouter } from 'next/router'
import React, { useMemo } from 'react'
interface LCH {
  lightness: number
  chroma: number
  hue: number
}

function hexToLCH(hex: string): LCH {
  // Convert RGBA to Chroma instance
  const color = chroma(hex)

  // Get LCH values
  const [lightness, chromaValue, hue] = color.hsl()

  // Return LCH object with original hue
  return {
    lightness,
    chroma: chromaValue,
    hue,
  }
}

interface ColorProperties {
  [key: string]: string
}

interface SaturatedColors {
  [key: string]: string
}

function saturateColorsWithPrimary(
  primaryColorHex: string,
  colors: ColorProperties,
  theme: 'light' | 'dark',
  customTheme: {
    graySaturation: number
    graySaturator: string
  }
): SaturatedColors {
  const primaryColor = chroma(customTheme.graySaturator)

  const targetHue = primaryColor.hsl()[0] // Using HSL for hue adjustment
  const modifiedColors: SaturatedColors = {}
  const light = theme === 'light'

  Object.entries(colors).forEach(([key, value]) => {
    const [h, s, l] = value.split(' ').map((n, i) => parseFloat(n) / (i === 0 ? 1 : 100))
    const originalColor = chroma.hsl(h, s, l)
    const adjustedColor = originalColor.set('hsl.h', targetHue) // Adjust hue while keeping other properties

    let newColorOpacity = theme === 'dark' ? customTheme.graySaturation / 100 : 0.2
    if (light) {
      if (key === 'background') {
        newColorOpacity = 0.4
      } else if (key === 'border') {
        newColorOpacity = 1
      }
    }
    const newColor = chroma.mix(originalColor, adjustedColor, newColorOpacity, 'oklch')

    modifiedColors[key] = getValueinHSLString(newColor.hex())
  })

  return modifiedColors
}

const getValueinHSLString = (color: string) => {
  const colorInstance = hexToLCH(color)
  return `${colorInstance.lightness || 0} ${colorInstance.chroma * 100 || 0}% ${
    colorInstance.hue * 100 || 0
  }%`
}

const colorProperties: ColorProperties = {
  background: '224 22% 13.2%',
  foreground: '227 21% 75%',
  'background-accent': '227 22% 46%',
  muted: '227 21% 18%',
  'muted-foreground': '226.667 21% 75% 0.8',
  'dark-accent': '227 21% 25%',
  'dark-accent-foreground': '225 22% 89%',
  popover: '226 24% 11%',
  'popover-foreground': '227 21% 75%',
  border: '226 20% 21%',
  input: '227 21% 18%',
  card: '226 22% 14.5%',
  'card-foreground': '227 21% 75%',
  secondary: '227 21% 18%',
  'secondary-foreground': '227 21% 75%',
  destructive: '0 63% 31%',
  'destructive-foreground': '210 40% 98%',
}

const colorPropertiesLight: ColorProperties = {
  background: '220 33% 98%',
  foreground: '227 21% 39%',
  muted: '225 22% 89% 0.4',
  'muted-foreground': '227 22% 46%',
  'background-accent': '227 22% 46%',
  popover: '0 0% 100%',
  'popover-foreground': '227 21% 39%',
  border: '225 22% 89% / 0.8',
  input: '225 22% 89%',
  card: '0 0% 100%',
  'card-foreground': '227 21% 39%',
  secondary: '0 0% 100%',
  'secondary-foreground': '226.667 22% 32%',
  'dark-accent': '225 22% 89% / 0.6',
  destructive: '0 100% 50%',
  'destructive-foreground': '210 40% 98%',
}

export const getCompiledTheme = (
  theme: 'light' | 'dark',
  org: IOrganization,
  returnObject = false
) => {
  const modifiedColors = saturateColorsWithPrimary(
    org?.color,
    theme === 'light' ? colorPropertiesLight : colorProperties,
    theme,
    !org.settings.customTheme?.enabled
      ? { graySaturator: org.color, graySaturation: 0.2, enabled: true }
      : org.settings.customTheme
  )
  if (returnObject) return modifiedColors
  let cssString = ''
  Object.entries(modifiedColors).forEach(([key, value]) => {
    cssString += `--${key}: ${value};`
  })
  return cssString
}

export const getDarkerPrimaryColor = (org: IOrganization) => {
  // Get color luminance
  const inputColor = org?.color || '#000'
  const color = chroma(inputColor)
  const luminance = color.luminance()

  let textColor = '#fff'

  if (luminance > 0.45) {
    // Make color much darker
    textColor = color.darken(5).hex()
  }

  let baseColor = chroma(luminance < 0.45 ? '#fff' : '#000')

  // Extract the lightness from the base color
  const baseLightness = baseColor.lch()[0] // L in LCH is similar to Lightness in HSL

  // Assuming 'newColorHex' is the hex code for the color you want to adjust
  // For demonstration, let's say we're changing to a different color
  // 'org?.color || '#000'' suggests optional chaining, implying 'org' might be an object with an optional color property
  let newColorHex = textColor || '#000' // Example new color hex

  // To adjust the new color to have the same lightness as the base color,
  // first, convert the new color hex to a Chroma color object to extract its C (chroma) and H (hue) values.
  let newColorLCH = chroma(newColorHex).lch()

  // Then, create a new color with the same lightness as the base color,
  // using the C and H from the new color's original LCH values.
  let adjustedColor = chroma.lch(baseLightness, newColorLCH[1], newColorLCH[2])

  // Now, get the hex value of the adjusted color
  newColorHex = adjustedColor.hex()

  return getValueinHSLString(newColorHex)
}

export const getLighterPrimaryColor = (org: IOrganization, returnHex = false) => {
  // Assume we're starting with a base color
  let baseColor = chroma('#818cf8')

  // Extract the lightness from the base color
  const baseLightness = baseColor.lch()[0] // L in LCH is similar to Lightness in HSL

  // Assuming 'newColorHex' is the hex code for the color you want to adjust
  // For demonstration, let's say we're changing to a different color
  // 'org?.color || '#000'' suggests optional chaining, implying 'org' might be an object with an optional color property
  let newColorHex = org?.color || '#000' // Example new color hex

  // To adjust the new color to have the same lightness as the base color,
  // first, convert the new color hex to a Chroma color object to extract its C (chroma) and H (hue) values.
  let newColorLCH = chroma(newColorHex).lch()

  // Then, create a new color with the same lightness as the base color,
  // using the C and H from the new color's original LCH values.
  let adjustedColor = chroma.lch(baseLightness, newColorLCH[1], newColorLCH[2])

  // Now, get the hex value of the adjusted color
  newColorHex = adjustedColor.hex()

  if (returnHex) return newColorHex

  return getValueinHSLString(newColorHex)
}

export const getRegularPrimaryColor = (org: IOrganization) => {
  // Assume we're starting with a base color
  let baseColor = chroma('#4f46e5')

  // Extract the lightness from the base color
  const baseLightness = baseColor.lch()[0] // L in LCH is similar to Lightness in HSL

  // Assuming 'newColorHex' is the hex code for the color you want to adjust
  // For demonstration, let's say we're changing to a different color
  // 'org?.color || '#000'' suggests optional chaining, implying 'org' might be an object with an optional color property
  let newColorHex = org?.color || '#000' // Example new color hex

  // To adjust the new color to have the same lightness as the base color,
  // first, convert the new color hex to a Chroma color object to extract its C (chroma) and H (hue) values.
  let newColorLCH = chroma(newColorHex).lch()

  // Then, create a new color with the same lightness as the base color,
  // using the C and H from the new color's original LCH values.
  let adjustedColor = chroma.lch(baseLightness, newColorLCH[1], newColorLCH[2])

  // Now, get the hex value of the adjusted color
  newColorHex = adjustedColor.hex()

  return getValueinHSLString(newColorHex)
}

function adjustLightnessToMatchBaseColor(baseColorHex: string, newColorHex: string): string {
  // Convert the base color to a Chroma color object
  const baseColor = chroma(baseColorHex)

  // Extract the lightness from the base color
  const baseLightness = baseColor.lch()[0] // L in LCH is similar to Lightness in HSL

  // Convert the new color hex to a Chroma color object to extract its LCH values
  const newColorLCH = chroma(newColorHex).lch()

  // Create a new color with the same lightness as the base color,
  // using the C and H from the new color's original LCH values
  const adjustedColor = chroma.lch(baseLightness, newColorLCH[1], newColorLCH[2])

  // Return the hex value of the adjusted color
  return adjustedColor.hex()
}

const CustomThemeHandler: React.FC<{ children: React.ReactNode; useCustomWhenDark?: boolean }> = ({
  children,
  useCustomWhenDark,
}) => {
  const { org } = useCurrentOrganization()

  const router = useRouter()

  const primaryColor = useMemo(() => {
    if (!org?.color)
      return {
        lightness: 0,
        chroma: 0,
        hue: 0,
      }
    return hexToLCH(org?.color)
  }, [org])

  const primaryColorLuminance = useMemo(() => {
    return getDarkerPrimaryColor(org)
  }, [org])

  const regularPrimaryColor = useMemo(() => {
    return getRegularPrimaryColor(org)
  }, [org])

  const lighterPrimaryColor = useMemo(() => {
    return getLighterPrimaryColor(org)
  }, [org])

  const darkerPrimaryColor = useMemo(() => {
    // Assume we're starting with a base color
    let baseColor = chroma('#000')

    // Extract the lightness from the base color
    const baseLightness = baseColor.lch()[0] // L in LCH is similar to Lightness in HSL

    // Assuming 'newColorHex' is the hex code for the color you want to adjust
    // For demonstration, let's say we're changing to a different color
    // 'org?.color || '#000'' suggests optional chaining, implying 'org' might be an object with an optional color property
    let newColorHex = org?.color || '#000' // Example new color hex

    // To adjust the new color to have the same lightness as the base color,
    // first, convert the new color hex to a Chroma color object to extract its C (chroma) and H (hue) values.
    let newColorLCH = chroma(newColorHex).lch()

    // Then, create a new color with the same lightness as the base color,
    // using the C and H from the new color's original LCH values.
    let adjustedColor = chroma.lch(baseLightness, newColorLCH[1], newColorLCH[2])

    // Now, get the hex value of the adjusted color
    newColorHex = adjustedColor.hex()

    return getValueinHSLString(newColorHex)
  }, [org])

  const accentForegroundDark = useMemo(() => {
    // Assume we're starting with a base color
    let baseColor = chroma('#c7d2fe')

    // Extract the lightness from the base color
    const baseLightness = baseColor.lch()[0] // L in LCH is similar to Lightness in HSL

    // Assuming 'newColorHex' is the hex code for the color you want to adjust
    // For demonstration, let's say we're changing to a different color
    // 'org?.color || '#000'' suggests optional chaining, implying 'org' might be an object with an optional color property
    let newColorHex = org?.color || '#000' // Example new color hex

    // To adjust the new color to have the same lightness as the base color,
    // first, convert the new color hex to a Chroma color object to extract its C (chroma) and H (hue) values.
    let newColorLCH = chroma(newColorHex).lch()

    // Then, create a new color with the same lightness as the base color,
    // using the C and H from the new color's original LCH values.
    let adjustedColor = chroma.lch(baseLightness, newColorLCH[1], newColorLCH[2])

    // Now, get the hex value of the adjusted color
    newColorHex = adjustedColor.hex()

    return getValueinHSLString(newColorHex)
  }, [org])

  const portalWidgetDarkBackground = useMemo(() => {
    const baseColorHex = '#4f46e5' // Base color hex code
    const newColorHex = org?.color || '#000' // New color hex code (with optional chaining)

    const adjustedColorHex = adjustLightnessToMatchBaseColor(baseColorHex, newColorHex)

    return getValueinHSLString(adjustedColorHex)
  }, [org])

  const portalWidgetLighterBackground = useMemo(() => {
    const baseColorHex = '#000' // Base color hex code
    const newColorHex = org?.color || '#000' // New color hex code (with optional chaining)

    const adjustedColorHex = adjustLightnessToMatchBaseColor(baseColorHex, newColorHex)

    return getValueinHSLString(adjustedColorHex)
  }, [org])

  // console.log('portalWidgetDarkBackground', portalWidgetDarkBackground)

  return (
    <>
      <style>
        {primaryColor &&
          `
            :root {
                --primary: ${primaryColor.lightness || 0} ${primaryColor.chroma * 100 || 0}% ${
                  primaryColor.hue * 100 || 0
                }%;
                --primary-foreground: ${primaryColorLuminance};
                --accent: ${lighterPrimaryColor};
                --accent-foreground: ${darkerPrimaryColor};
                --modified-primary: ${regularPrimaryColor};
                   --portal-widget-dark-background: ${portalWidgetDarkBackground};
                --portal-widget-lighter-background: ${portalWidgetLighterBackground};
              }
              .dark {
                --primary: ${primaryColor.lightness || 0} ${primaryColor.chroma * 100 || 0}% ${
                  primaryColor.hue * 100 || 0
                }%;
                --primary-foreground: ${primaryColorLuminance};
                --accent: ${lighterPrimaryColor};
                --accent-foreground: ${accentForegroundDark};
                --modified-primary: ${regularPrimaryColor};
                ${
                  !router.pathname.startsWith('/dashboard') && !org?.settings?.customTheme?.enabled
                    ? '--background: 224 22% 13.2%;'
                    : ''
                }
                  ${
                    (!router.pathname.startsWith('/dashboard') &&
                      org?.settings?.customTheme?.enabled) ||
                    useCustomWhenDark
                      ? getCompiledTheme('dark', org)
                      : ''
                  }
                --portal-widget-dark-background: ${portalWidgetDarkBackground};
                --portal-widget-lighter-background: ${portalWidgetLighterBackground};
            }
            `}
      </style>
      {children}
    </>
  )
}

export default CustomThemeHandler
