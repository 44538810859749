import { uploadImageCloudflare } from 'network/lib/organization'
import { toast } from 'sonner'
import { createImageUpload } from './extensions/HandleNonCommandImage/regular-upload-images'

const onUpload = (file: File) => {
  // Create a new FormData object and append the file
  const formData = new FormData()
  formData.append('image', file)

  // Start uploading using the Cloudflare upload function
  const promise = uploadImageCloudflare(formData)

  return new Promise((resolve, reject) => {
    toast.promise(
      promise
        .then((res) => {
          // Check if the response contains the expected data
          if (res.status === 200) {
            const { src } = res.data
            // Preload the image
            let image = new Image()
            image.src = src
            image.onload = () => {
              resolve(src)
            }
          } else {
            throw new Error('Error uploading image. Please try again.')
          }
        })
        .catch((err) => {
          toast.error(err.response?.data.error)
          reject(err.response?.data.error)
        }),
      {
        loading: 'Uploading image...',
        success: 'Image uploaded successfully.',
        error: (e) => e.message,
      }
    )
  })
}

export const uploadFn = createImageUpload({
  onUpload,
  validateFn: (file: any) => {
    if (!file.type.includes('image/')) {
      toast.error('File type not supported.')
      return false
    } else if (file.size / 1024 / 1024 > 20) {
      toast.error('File size too big (max 20MB).')
      return false
    }
    return true
  },
})
