import { defaultChat } from '@/components/FBAIBot'
import { IMemberRolePermissions } from '@/interfaces/IOrganization'
import { atom } from 'jotai'
import { InstantMeiliSearchInstance } from '@meilisearch/instant-meilisearch'

export const subdomainAtom = atom<string>('')
export const upgradePlanAtom = atom<
  | undefined
  | {
      title: string
      plan: string
    }
>(undefined)

export const missingPermissionAtom = atom<
  | undefined
  | {
      permission: keyof IMemberRolePermissions
    }
>(undefined)

export const metaDataAtom = atom<object | undefined>({})
export const meilisearchClientAtom = atom<undefined | InstantMeiliSearchInstance>(undefined)
export const searchKeyAtom = atom<string>('')
export const devDocsSelectedOrgAtom = atom<string | null>(null)
export const forcedThemeAtom = atom<'dark' | 'light' | 'client' | undefined>(undefined)
export const hidePublicMenuAtom = atom<boolean>(false)
export const hideLogoAtom = atom<boolean>(false)
export const domainTypeAtom = atom<'feedback' | 'helpcenter'>('feedback')
export const helpCenterUrlPartsAtom = atom<{
  subpath: string
  locale: string
  helpCenterId: string
  articleId: string
}>({
  subpath: '/help/',
  locale: 'en',
  helpCenterId: '',
  articleId: '',
})
export const fullRoadmapAtom = atom<boolean>(false)
export const chatWithAIAtom = atom<boolean>(false)
export const chatWithAIInputAtom = atom<string>('')
export const chatWithAIResponseAtom = atom<
  {
    role: string
    html: string
    cursor: string
  }[]
>([defaultChat])
export const loadingAIResponseAtom = atom<string>('')
export const showIntercomChatAtom = atom<boolean>(false)
export const orgThemeAtom = atom<{
  background: string
  foreground: string
  'background-accent': string
  muted: string
  'muted-foreground': string
  'dark-accent': string
  'dark-accent-foreground': string
  popover: string
  'popover-foreground': string
  border: string
  input: string
  card: string
  'card-foreground': string
  secondary: string
  'secondary-foreground': string
  destructive: string
  'destructive-foreground': string
} | null>(null)
